/* 
   This file is copied version of variables.css, princess-style-library.css from global docroot
   This file needs to be updated after any big overhaul in the files 
*/


/* All Variables defined for mobile-specificity  */
:root {
    /* ~~~~~~~~~~~~~~~~~~MODERN~~~~~~~~~~~~~~~~~ */
    /**
        The definitions of the variables below are all implementations of the newly standardized Visual Expressions project,
        there are a handful of repeats which match the variables below in Legacy; they exist to provide coverage for both
        new naming and old naming. NOTE: ALL NEW DEVELOPMENT POST VISUAL EXPRESSIONS LAUNCH SHOULD USE THE VARIABLES BELOW.
     */
    /*                  COLORS                  */
    --dark: #020215;
    --gold: #aa8247;
    --gold-dark-300: #775b32;
    --passion-pink: #e60060;
    --passion-pink-dark-200: #bb004f;
    --passion-pink-light-100: #fde6ef;
    --princess-blue: #003595;
    --princess-blue-dark-200: #002a77;
    --princess-blue-light-100: #e6ebf4;
    --princess-blue-light-200: #ccd7ea;
    --princess-blue-light-400: #99aed5;
    --princess-blue-light-600: #6786be;
    --princess-blue-light-700: #4d72b5;
    --princess-blue-light-800: #335daa;
    --sand-light-500: #f9f7f6;
    --sand-light-700: #f6f4f2;
    --sea-foam: #cce7eb;
    --steel-grey: #6c6c78;
    /* Transparency */
    --dark-15: #02021526;
    --dark-30: #0202154d;
    --white-15: #ffffff26;
    --white-30: #ffffff4d;
    --white-40: #ffffff66;
    --white-5: #ffffff0d;
    --white-50: #ffffff80;
    --white-60: #ffffff99;
    --white-80: #ffffffcc;
    --sand-dark-500: #797877;
    --sand-dark-600: #61605f;
    --sand-dark-700: #494847;
    --sand-dark-800: #30302f;
    --sand-dark-900: #181818;
    --sand-light-100: #ffffff;
    --sand-light-200: #fcfcfb;
    --sand-light-300: #fbfafa;
    --sand-light-400: #faf9f8;
    --sand-light-500: #f9f7f6;
    --sand-light-600: #f7f5f4;
    --sand-light-700: #f6f4f2;
    --sand-light-800: #f5f2f1;
    --sand-light-900: #f3f1ef;
    --sea-foam: #cce7eb;
    --sea-foam-dark-100: #b8d0d4;
    --sea-foam-dark-200: #a3b9bc;
    --sea-foam-dark-300: #8fa2a5;
    --sea-foam-dark-400: #7a8b8d;
    --sea-foam-dark-500: #667476;
    --sea-foam-dark-600: #525c5e;
    --sea-foam-dark-700: #3d4546;
    --sea-foam-dark-800: #292e2f;
    --sea-foam-dark-900: #141717;
    --sea-foam-light-100: #fafdfd;
    --sea-foam-light-200: #f5fafb;
    --sea-foam-light-300: #f0f8f9;
    --sea-foam-light-400: #ebf5f7;
    --sea-foam-light-500: #e6f3f5;
    --sea-foam-light-600: #e0f1f3;
    --sea-foam-light-700: #dbeef1;
    --sea-foam-light-800: #d6ecef;
    --sea-foam-light-900: #d1e9ed;
    --steel-grey: #6c6c78;
    --steel-grey-dark-100: #61616c;
    --steel-grey-dark-200: #565660;
    --steel-grey-dark-300: #4c4c54;
    --steel-grey-dark-400: #414148;
    --steel-grey-dark-500: #36363c;
    --steel-grey-dark-600: #2b2b30;
    --steel-grey-dark-700: #202024;
    --steel-grey-dark-800: #161618;
    --steel-grey-dark-900: #0b0b0c;
    --steel-grey-light-100: #f0f0f2;
    --steel-grey-light-200: #e2e2e4;
    --steel-grey-light-300: #d3d3d7;
    --steel-grey-light-400: #c4c4c9;
    --steel-grey-light-500: #b6b6bc;
    --steel-grey-light-600: #a7a7ae;
    --steel-grey-light-700: #9898a1;
    --steel-grey-light-800: #898993;
    --steel-grey-light-900: #7b7b86;
    --white: #ffffff;

    /* TOKENIZED COLORS  */
    --background-accent-blue: var(--princess-blue);
    --background-accent-blue-subtle: var(--princess-blue-light-100);
    --background-accent-dark: var(--dark);
    --background-accent-pink: var(--passion-pink);
    --background-accent-sea-foam: var(--sea-foam);
    --background-accent-sea-foam-subtle: var(--sea-foam-light-300);
    --background-button-blue-version-fab-default: var(--white);
    --background-button-blue-version-image-default: var(--white);
    --background-button-blue-version-primary-default: var(--primary-cta);
    --background-button-blue-version-primary-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-primary-hover: var(--primary-cta-hover);
    --background-button-blue-version-primary-inverted-default: var(--white);
    --background-button-blue-version-primary-inverted-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-primary-inverted-hover: var(--princess-blue-light-200);
    --background-button-blue-version-tertiary-default: var(--princess-blue);
    --background-button-blue-version-tertiary-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-tertiary-hover: var(--princess-blue-dark-200);
    --background-button-blue-version-tertiary-inverted-default: var(--gold);
    --background-button-blue-version-tertiary-inverted-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-tertiary-inverted-hover: var(--gold-dark-300);
    --background-button-pink-version-fab-default: var(--white);
    --background-button-pink-version-image-default: var(--white);
    --background-button-pink-version-primary-default: var(--passion-pink);
    --background-button-pink-version-primary-disabled: var(--steel-grey-light-300);
    --background-button-pink-version-primary-hover: var(--passion-pink-dark-200);
    --background-button-pink-version-primary-inverted-default: var(--white);
    --background-button-pink-version-primary-inverted-disabled: var(--steel-grey-light-300);
    --background-button-pink-version-primary-inverted-hover: var(--passion-pink-light-100);
    --background-button-pink-version-tertiary-default: var(--passion-pink);
    --background-button-pink-version-tertiary-disabled: var(--steel-grey-light-300);
    --background-button-pink-version-tertiary-hover: var(--passion-pink-dark-200);
    --background-button-pink-version-tertiary-inverted-default: var(--passion-pink);
    --background-button-pink-version-tertiary-inverted-disabled: var(--steel-grey-light-300);
    --background-control-toggle-active: var(--princess-blue);
    --background-control-toggle-disabled: var(--dark-15);
    --background-control-toggle-inverted-active: var(--white);
    --background-control-toggle-inverted-disabled: var(--white-30);
    --background-input-default: var(--white);
    --background-input-disabled: var(--steel-grey-light-200);
    --background-input-inverted-default: var(--white-5);
    --background-input-inverted-disabled: var(--white-15);
    --background-label-blue: var(--princess-blue);
    --background-label-pink: var(--passion-pink);
    --background-label-sea-foam: var(--sea-foam);
    --background-label-white: var(--white);
    --background-surface-primary: var(--sand-light-500);
    --background-surface-secondary: var(--white);
    --border-base-divider: var(--steel-grey-light-300);
    --border-button-blue-version-image-default: var(--dark);
    --border-button-blue-version-secondary-default: var(--princess-blue);
    --border-button-blue-version-secondary-disabled: var(--steel-grey-light-300);
    --border-button-blue-version-secondary-hover: var(--princess-blue-dark-200);
    --border-button-blue-version-secondary-inverted-default: var(--white);
    --border-button-blue-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --border-button-pink-version-image-default: var(--dark);
    --border-button-pink-version-secondary-default: var(--passion-pink);
    --border-button-pink-version-secondary-disabled: var(--steel-grey-light-300);
    --border-button-pink-version-secondary-hover: var(--passion-pink-dark-200);
    --border-button-pink-version-secondary-inverted-default: var(--white);
    --border-button-pink-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --border-control-tab-active: var(--passion-pink);
    --border-control-tab-default: var(--steel-grey-light-800);
    --border-control-toggle-default: var(--dark);
    --border-control-toggle-hover: var(--steel-grey);
    --border-control-toggle-inverted-default: var(--white);
    --border-control-toggle-inverted-hover: var(--sand-light-700);
    --border-focus-default: var(--dark);
    --border-focus-default-inverted: var(--white);
    --border-input-active: var(--princess-blue);
    --border-input-default: var(--steel-grey-light-800);
    --border-input-disabled: var(--steel-grey-light-800);
    --border-input-hover: var(--steel-grey-dark-300);
    --border-input-inverted-active: var(--white);
    --border-input-inverted-default: var(--white-40);
    --border-input-inverted-disabled: var(--white-30);
    --border-input-inverted-hover: var(--white-80);
    --icon-accent-blue: var(--princess-blue);
    --icon-accent-pink: var(--passion-pink);
    --icon-base-primary: var(--dark);
    --icon-base-primary-inverted: var(--white);
    --icon-base-secondary: var(--steel-grey);
    --icon-base-secondary-inverted: var(--white-60);
    --icon-interactive-blue-default: var(--princess-blue);
    --icon-interactive-blue-disabled: var(--steel-grey-light-300);
    --icon-interactive-blue-hover: var(--princess-blue-dark-200);
    --icon-interactive-gold-default: var(--gold);
    --icon-interactive-gold-disabled: var(--steel-grey-light-300);
    --icon-interactive-gold-hover: var(--gold-dark-300);
    --icon-interactive-link-default: var(--dark);
    --icon-interactive-pink-default: var(--passion-pink);
    --icon-interactive-pink-disabled: var(--steel-grey-light-300);
    --icon-interactive-pink-hover: var(--passion-pink-dark-200);
    --icon-interactive-primary-default: var(--steel-grey);
    --icon-interactive-primary-hover: var(--dark);
    --icon-interactive-primary-inverted-default: var(--steel-grey-light-300);
    --icon-interactive-primary-inverted-hover: var(--white);
    --icon-interactive-secondary-default: var(--dark);
    --icon-interactive-secondary-hover: var(--steel-grey);
    --icon-interactive-secondary-inverted-default: var(--white);
    --icon-interactive-secondary-inverted-hover: var(--steel-grey-light-300);
    --icon-interactive-toggle-active: var(--white);
    --icon-interactive-toggle-disabled: var(--dark-30);
    --icon-interactive-toggle-inverted-active: var(--princess-blue);
    --icon-interactive-toggle-inverted-disabled: var(--white-50);
    --text-accent-blue: var(--princess-blue);
    --text-accent-pink: var(--passion-pink);
    --text-base-primary: var(--dark);
    --text-base-primary-inverted: var(--white);
    --text-base-secondary: var(--steel-grey);
    --text-button-blue-version-fab-default: var(--dark);
    --text-button-blue-version-image-default: var(--dark);
    --text-button-blue-version-link-default: var(--dark);
    --text-button-blue-version-link-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-primary-default: var(--white);
    --text-button-blue-version-primary-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-primary-inverted-default: var(--princess-blue);
    --text-button-blue-version-primary-inverted-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-primary-inverted-hover: var(--princess-blue-dark-200);
    --text-button-blue-version-secondary-default: var(--dark);
    --text-button-blue-version-secondary-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-secondary-inverted-default: var(--white);
    --text-button-blue-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --text-button-blue-version-tertiary-default: var(--dark);
    --text-button-blue-version-tertiary-inverted-default: var(--white);
    --text-button-pink-version-fab-default: var(--dark);
    --text-button-pink-version-image-default: var(--dark);
    --text-button-pink-version-link-default: var(--dark);
    --text-button-pink-version-link-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-primary-default: var(--white);
    --text-button-pink-version-primary-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-primary-inverted-default: var(--passion-pink);
    --text-button-pink-version-primary-inverted-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-primary-inverted-hover: var(--passion-pink-dark-200);
    --text-button-pink-version-secondary-default: var(--dark);
    --text-button-pink-version-secondary-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-secondary-inverted-default: var(--white);
    --text-button-pink-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --text-button-pink-version-tertiary-default: var(--dark);
    --text-button-pink-version-tertiary-inverted-default: var(--white);
    --text-label-blue: var(--princess-blue);
    --text-label-pink: var(--passion-pink);
    --text-label-white: var(--white);


    /*             TYPOGRAPHY SIZING TOKENS                */
    --size-additional-styles-tag: 16px;
    --size-additional-styles-eyebrow: 20px;
    --size-additional-styles-quote: 32px;
    --size-additional-styles-xl: 180px;
    --size-body-copy-body-copy-small: 14px;
    --size-body-copy-body-copy-medium: 18px;
    --size-body-copy-body-copy-large: 22px;
    --size-body-copy-body-copy-x-large: 25px;
    --size-breadcrumbs-current-page: 14px;
    --size-breadcrumbs-previous-page: 14px;
    --size-call-to-action-cta-button-text-link: 16px;
    --size-call-to-action-cta-button-text-small: 16px;
    --size-call-to-action-tertiary-cta-button-text: 18px;
    --size-call-to-action-cta-button-text-large: 20px;
    --size-call-to-action-cta-button-text-medium: 20px;
    --size-display-headline-xxsmall: 25px;
    --size-display-headline-xsmall: 32px;
    --size-display-headline-small: 44px;
    --size-display-headline-medium: 70px;
    --size-display-headline-large: 88px;
    --size-form-component-styles-form-paragraph : 16px;
    --size-form-component-styles-form-list-item : 16px;
    --size-form-component-styles-form-legend : 16px;
    --size-form-component-styles-form-advisory: 14px;
    --size-form-component-styles-form-label: 16px;
    --size-form-component-styles-form-value: 16px;
    --size-section-title-desktop-title-example: 22px;
    --size-subtitles-sub-headline-small: 18px;
    --size-subtitles-sub-headline-medium: 22px;
    --size-subtitles-sub-headline-large: 24px;

    /*                     TYPOGRAPHY FONT FAMILIES                   */
    /* Used for most everything content; body and large texts, etc.*/
    --proxima-nova: 'Proxima Nova', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-it: 'Proxima Nova Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-thin: 'Proxima Nova Thin', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-thin-it: 'Proxima Nova Thin Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-light: 'Proxima Nova Light', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-light-it: 'Proxima Nova Light Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-medium: 'Proxima Nova Medium', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-medium-it: 'Proxima Nova Medium Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-semi-bold: 'Proxima Nova Semibold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-semi-bold-it: 'Proxima Nova Semibold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-bold: 'Proxima Nova Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-bold-it: 'Proxima Nova Bold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-black: 'Proxima Nova Black', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-black-it: 'Proxima Nova Black Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-extra-bold: 'Proxima Nova Extrabold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-extra-bold-it: 'Proxima Nova Extrabold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    /* Used for large fonts, mostly headers. */
    --domaine-display-regular: 'Domaine Display Regular', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --domaine-display-medium: 'Domaine Display Medium', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --domaine-display-bold: 'Domaine Display Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;



    /* ~~~~~~~~~~~~~~~~~~LEGACY~~~~~~~~~~~~~~~~~~~~~ */
    /**
        The odd mapping for all of the variables below is to handle a wide range of styling cases which span across all of princess.com (non-aem)
        which did not implement the new naming and variable conventions as above.
     */
    /* Source Sans 3 is used as a backup to support certain superscript tags that are unsupported by our primary fonts.*/
    --red-hat-text: var(--proxima-nova);
    --red-hat-text-it: var(--proxima-nova-it);
    --red-hat-text-bold: var(--proxima-nova-bold);
    --red-hat-text-bold-it: var(--proxima-nova-bold-it);
    --red-hat-text-medium: var(--proxima-nova-medium);
    --red-hat-text-medium-it: var(--proxima-nova-medium-it);
    /* Other mappings as needed */
    --gotham-xn-book: var(--proxima-nova);
    --gotham-xn-medium: var(--proxima-nova-medium);
    --gotham-book: var(--proxima-nova);
    --gotham-book-it: var(--proxima-nova-it);
    --gotham-bold: var(--proxima-nova-bold);
    --gotham-bold-it: var(--proxima-nova-bold-it);
    --roboto-condensed: var(--proxima-nova);
    --roboto-condensed-bold: var(--proxima-nova-bold);
    /* Lora - Headers */
    --lora-medium-it: var(--domaine-display-regular);
    --lora-bold-it: var(--domaine-display-bold);
    --default-sans-serif: 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;

    /*
     * Font scaling based on a base unit of 14px
     */
    --font-size-m3:  0.6875em;  /*11px*/
    --font-size-m2:  0.75em;    /*12px*/
    --font-size-m1:  0.8125em;  /*13px*/
    --font-size-0:   0.875em;   /*14px*/
    --font-size-p1:  0.9375em;  /*15px*/
    --font-size-p2:  1em;   /*16px*/
    --font-size-p3:  1.0625em;   /*17px*/
    --font-size-p4:  1.125em;   /*18px*/
    --font-size-p5:  1.1875em;   /*19px*/
    --font-size-p6:  1.25em;   /*20px*/
    --font-size-p7:  1.3125em;  /*21px*/
    --font-size-p8:  1.375em;   /*22px*/
    --font-size-p9:  1.4375em;   /*23px*/
    --font-size-p10: 1.5em;     /*24px*/
    --font-size-p11: 1.5625em;   /*25px*/
    --font-size-p12: 1.625em;   /*26px*/
    --font-size-p13: 1.6875em;   /*27px*/
    --font-size-p14: 1.75em;     /*28px*/
    --font-size-p15: 1.8125em;   /*29px*/
    --font-size-p16: 1.875em;   /*30px*/
    --font-size-p17: 1.9375em;   /*31px*/
    --font-size-p18: 2em;   /*32px*/
    --font-size-p19: 2.0625em;   /*33px*/
    --font-size-p20: 2.125em;   /*34px*/
    --font-size-p21: 2.1875em;                 /*35px*/
    --font-size-p22: 2.25em;   /*36px*/
    --font-size-p23: 2.3125em;   /*37px*/
    /*
    * Branded colors
    */
    --primary-blue: #003595;                /** updated - name from primary and color */
    --primary-blue-dark: #002568;           /** updated - name from princess-blue-alt and color */
    --primary-dark: #020215;                /** updated - name from neutral-grey-dark and color */
    --primary-medium: #6C6C78;              /** new - name from neutral-grey-dark and color */
    --primary-light: #F2EFED;               /** updated - name from neutral-warm-light and color */
    --primary-extra-light: #F8F6F2;         /** added - name from neutral-warm-light and color */

    /*
     * Colors to communicate state
     */
    --primary-cta: linear-gradient(180deg, #1A5AD7, #003595);
    --primary-cta-hover: linear-gradient(180deg, #1A5AD7, #002568);
    --secondary-cta: linear-gradient(180deg, #ffffff, #f1f1f1);
    --secondary-cta-hover: linear-gradient(180deg, #ffffff, #f9f9f9);
    --success: #007a7c;                     /** added */
    --success-dark: #035c67;                /** added */
    --caution: #d4673c;                     /** added */
    --caution-dark: #b54d24;                /** added */
    --error: #b92d2d;                       /** added */
    --error-dark: #9a2626;                  /** added */
    --error-bg: #fdfce3;                    /** updated - name from bg-error */
    --inactive: rgba(255, 255, 255, .7);   /** added - name from bg-error */

    /*
     * Colors to communicate loyality level status
     */
    --loyalty-blue: #003595;           /** updated */
    --loyalty-gold: #cc9f53;           /** updated */
    --loyalty-ruby: #8e1537;           /** updated */
    --loyalty-platinum: #7b868c;       /** updated */
    --loyalty-elite: #000000;          /** updated */

    /*
     * Accent Treatments
     */
    --accent-backdrop: #787c86;             /** added */
    --accent-form: #e5f3fd;                 /* updated - name from bg-form */
    --accent-gold: #cc9f53;                 /** added */
    --accent-gold-dark:#aa8247;             /** added */
    --accent-silver: #cdd1d5;               /** added */
    --accent-pink: #EA0063;                 /** added */
    --accent-pink-dark: #D7005B;

    /*
     * Transparency treatments
     */
    --transparent-dark: rgba(2, 2, 21, .7);                     /* added */
    --transparent-medium: rgba(2, 2, 21, .5);              /* added */
    --transparent-light: rgba(255, 255, 255, .7);               /* added */
    --transparent-glow: radial-gradient(50% 71.67% at 50% 100%, rgba(195, 186, 180, 0.4) 0%, rgba(242, 239, 237, 0.2) 89.06%);          /** added */
    --transparent-wave: radial-gradient(128.8% 146.38% at 8.67% 69.32%, rgba(195, 186, 180, 0.16) 0%, rgba(242, 239, 237, 0.4) 100%);   /** added */
    --transparent-glass: linear-gradient(333.35deg, rgba(255, 255, 255, 0.3) 31.34%, rgba(255, 255, 255, 0.2) 75.44%);                  /** added */

    /*
     * Auxiliary colors to fill gaps in old colors that are not available in new brand colors
     */
    --auxiliary-blue-dark: #09213d;     /** updated - name from bg-extra-dark and color */
    --auxiliary-blue: #1A5AD7;     /** updated - name from bg-extra-dark and color */
    --auxiliary-blue-light: #e5f3fd;    /** updated - name from bg-light and color */
    --auxiliary-blue-extra-light: #f7fbfe;    /** updated - name from bg-light and color */
    --auxiliary-green: #6d9d5b;                   /** updated - color */
    --auxiliary-green-light: #EBF3F3;
    /*--auxiliary-green-dark: #035c67;              /** updated - name from secondary-dark and color */
    --auxiliary-red: #af3636;                        /** added */
    --auxiliary-red-dark: #912d2d;                   /** added */
    --auxiliary-orange: #FCB215;                   /** added */
    --auxiliary-secondary-blue: #009dd2;

    /*
     * Neutrals colors for backgrounds, borders, and text treatments
     */
    --neutral-black: #000;                  /* keep */
    --neutral-white: #fff;                  /* keep */

    --neutral-dark: #333537;                /* keep */
    --neutral-medium: #cccccc;              /* updated - name from neutral-grey and color */
    --neutral-light: #e2e3e4;               /* updated - name from neutral-header-bg and color */

    --neutral-cool-dark: #54575a;           /* keep */
    --neutral-cool-medium: #939598;         /* updated - name from neutral-cool-medium and color */
    --neutral-cool-light: #f1f1f1;          /* keep */
    --neutral-cool-extra-light: #f9f9f9;    /* keep */

    --neutral-warm-medium: #eae5dc;          /* updated - color */
    --neutral-warm-light: #f3efee;           /* updated - color */

    /*
     * Removed variable names

    --ocean-blue: #003087;                    removing
    --font-blue: #0054A0;                     removing
    --primary: #6d9d5b;                       removing
    --primary-dark: #587f49;                  removing
    --primary-alt: #4a6b3e;                   removing
    -secondary: #cc9f53;                      removing - color
    -secondary-dark: #b18234;                 removing - name from secondary-alt and color
    -primary-extra-dark: #09213d;          removing - name from princess-blue-alt and color
    -primary-extra-light: #f7fbfe;         removing - name from bg-form
    --bg-extra-dark: #09213d;              removing
    --bg-dark: #0d2f56;                    removing
    --bg-form: #e5f3fd;                    removing
    --bg-light: #f7fbfe;                   removing
    --bg-light-alt: #ebf3f3;               removing


    /*
     * Design Treatments
     */
    --border-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    --top-box-shadow: 0px -4px 9px -3px #00000033;
    --btm-box-shadow: 0px 5px 6px #0000004D;
}

/* TODO: define mobile breakpoint. */
@media (max-width: 600px) {
    :root {
        --size-additional-styles-eyebrow: 16px;
        --size-additional-styles-tag: 16px;
        --size-additional-styles-quote: 22px;
        --size-additional-styles-xl: 70px;
        --size-body-copy-body-copy-small: 14px;
        --size-body-copy-body-copy-medium: 16px;
        --size-body-copy-body-copy-large: 18px;
        --size-body-copy-body-copy-x-large: 20px;
        --size-breadcrumbs-current-page: 14px;
        --size-breadcrumbs-previous-page: 14px;
        --size-call-to-action-cta-button-text-link: 16px;
        --size-call-to-action-cta-button-text-small: 16px;
        --size-call-to-action-tertiary-cta-button-text: 16px;
        --size-call-to-action-cta-button-text-large: 20px;
        --size-call-to-action-cta-button-text-medium: 20px;
        --size-display-headline-xxsmall: 22px;
        --size-display-headline-xsmall: 24px;
        --size-display-headline-small: 32px;
        --size-display-headline-medium: 40px;
        --size-display-headline-large: 56px;
        --size-form-component-styles-form-advisory: 14px;
        --size-form-component-styles-form-label: 14px;
        --size-form-component-styles-form-value: 14px;
        --size-section-title-desktop-title-example: 19px;
        --size-subtitles-sub-headline-small: 16px;
        --size-subtitles-sub-headline-medium: 18px;
        --size-subtitles-sub-headline-large: 20px;
    }
}

/**
    The purpose of this file is to split out some of the styling found in princess-noramlize.css, which over the years
    has turned into a big mess of a file with a lot of repetition and un-audited class definitions.
 */

/*
 * Various Utility Classes Made Available at Princess Global Projects (non-AEM)
 */

/* VISUAL EXPRESSIONS BUTTONS TODO: if global wants new buttons, switch these-- */

/* BUTTONS */
/* Default Button - Used on input, button, hrefs.

    NOTE: New styling has been implemented here and all buttons as a retrofit using the PCL Master Design System Figma as of 03/20/2023.
    It is a retrofit because the goal is to transition these, as well as all of our other global styling over to pcl-ui-core, the modernized
    pcl front-end docroot which is built based on BEM, SASS, and Components.

    For this class particularly, it's been modified to hold all styling
    which all of our CTA buttons share, such as border-radius, and acts as a default.

    Because the elements found globally which hold only the .button class are considered Secondary, Secondary CTAs act and are defined as the "default" button.
    Also includes the .secondary-btn class for projects ready to switch over to the new class name.
 */
 #search-widget {
    .button, 
    .secondary-btn {
        /* All CTA button styling */
        border-radius: 4px;
        padding: 12px 16px;
        margin: 0;
        line-height: 150%;
        text-decoration: none;
        letter-spacing: 1px;
        display: inline-flex;
        align-items: center;
        place-content: center;
        white-space: nowrap;
        cursor: pointer;
        /* Default Button Styling for Secondaries*/
        border: solid 1px var(--primary-blue);
        background: transparent;
        color: var(--primary-blue);
    }
    /* Made the selectors a couple levels more specific to take priority for various problematic styling, but still overwriteable.*/
    .web-font body .button,
    .flexbox body .button,
    .web-font body .secondary-btn {
        font-size: var(--size-call-to-action-cta-button-text-medium);
        text-transform: uppercase;
        font-family: var(--proxima-nova);
    }
    .web-font body .button.btn-sm,
    .flexbox body .button.btn-sm {
        font-size: var(--size-call-to-action-cta-button-text-small);
    }
    
    
    /*
        Secondary Button Dark BG - Variant of Secondary CTA Button
        For different upcoming designs, as well as the existing "CTA Bar" found on pages like p.com/learn/cruise-destinations/alaska-cruises (at the bottom),
        a dark-bg variant is defined here
    */
    /* Legacy */
    .cta-bar .button,
        /* Modernized */
    .secondary-btn-dark-bg {
        border: 1px solid var(--neutral-white);
        color: var(--neutral-white);
    }
    
    /*
        By default, all buttons signify a hover/focus with an underline. This should cover all of them, but there
        are additional definitions in the variant blocks below to cover each variants specific hover/focus.
    */
    .button:not(.plain-text-btn):hover, .button:not(.plain-text-btn):focus,
    .button.selected:not(.plain-text-btn):hover, .button.selected:not(.plain-text-btn):focus {
        text-decoration: underline;
    }
    .web-font .button {
        font-style: normal;
        line-height: 21px;
    }
    .button:focus, .button.selected:focus {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, .8);
        outline: 2px dotted rgba(255, 255, 255, .8);
        outline-offset: 0;
    }
    .button:active {
        box-shadow: inset 0 0 3px 0 rgba(0,0,0,.75);
    }
    .button.selected {
        box-shadow: inset 0 0 3px 0 rgba(0,0,0,.75);
    }
    /* CTA (Call to Action) Button Variants */
    /*
        Retrofit of all of the old .[color]-btn classes with new cta button colors, styles, and variants.
    
        NOTE: A challenge to be aware of is that, in the new design standards we have primary, secondary, and tertiary buttons defined
        with variants, like small/large dark-bg/light-bg. In the new ui-core, these will be more precisely defined and implemented in BEM, with nested modifiers.
        For legacy, the naming and selectors might be a little more difficult to understand, since we're in transition.
    */
    /* Base Primary CTA -
        In most locations of the new designs,
        the Primary CTA button design will be implemented. TODO: Currently checking if stuffing all of the old color classes in here will work.
        Included here is the new class name, which will eventually be implemented everywhere, .primary-btn.
    */
    /* Legacy */
    .green-btn, .green-btn.selected,
    .gray-btn, .gray-btn.selected,
    .blue-btn, .blue-btn.selected,
    .red-btn, .red-btn.selected,
        /* Modernized */
    .primary-btn, .primary-btn.selected {
        background: var(--primary-cta);
        color: var(--neutral-white);
    }
    
    /* Primary CTA Base Dark BG Variant -
        TODO: unsure if dark-bg will work here, since dark-bg was only defined inside princess.pill
    */
    .green-btn.btn-dark-bg,.green-btn.selected,
    .white-btn, .white-btn.selected,
    a:hover .white-btn, a:focus .white-btn,
    a:hover .white-btn.selected, a:focus .white-btn.selected,
    .primary-btn-dark-bg, .primary-btn-dark-bg.selected,
    .green-btn.btn-dark-bg:hover,.green-btn.selected:hover,
    .primary-btn-dark-bg:hover, .primary-btn-dark-bg.selected:hover {
        background: var(--neutral-white);
        color: var(--primary-blue);
    }
    /* Primary Cta Hover & Focus */
    /* Legacy */
    .green-btn:hover, .green-btn:focus, .green-btn.selected:hover, .green-btn.selected:focus,
    .gray-btn:hover, .gray-btn:focus, .gray-btn.selected:hover, .gray-btn.selected:focus
    .blue-btn:hover, .blue-btn:focus, .blue-btn.selected:hover, .blue-btn.selected:focus,
    .red-btn:hover, .red-btn:focus, .red-btn.selected:hover, .red-btn.selected:focus, .red-btn.selected:hover, .red-btn.selected:focus,
        /* Modernized */
    .primary-btn:hover, .primary-btn:focus, .primary-btn.selected:hover, .primary-btn.selected:focus {
        background: var(--primary-cta-hover);
        text-decoration: underline;
    }
    
    /* Primary Cta Deactive State -
        TODO: For future designs, CTAs will never be disabled/deactive. This is a legacy-only style. Remove in the migration.
    */
    /* Legacy */
    .green-btn.deactive, .green-btn.selected.deactive, .green-btn.deactive:hover, .green-btn.deactive:focus,
    .gray-btn.deactive, .gray-btn.deactive:hover, .gray-btn.deactive:focus,
    .blue-btn.deactive, .blue-btn.deactive:hover, .blue-btn.deactive:focus,
    .red-btn.deactive, .red-btn.deactive:hover, .red-btn.deactive:focus,
    .white-btn.deactive, .white-btn.deactive:hover, .white-btn.deactive:focus,
    a.deactive:hover .white-btn, a.deactive:focus,
        /* Modernized */
    .primary-btn.deactive, .primary-btn.selected.deactive, .primary-btn.deactive:hover, .primary-btn.deactive:focus{
        background: var(--primary-medium);
        color: var(--neutral-white);
        cursor: not-allowed;
    }
    /* Tertiary CTA Button Variant Styles */
    /* Default plain text buttons - used on button */
    .plain-text-btn,
    .tertiary-btn {
        background: transparent;
        border: none;
        color: var(--primary-blue);
        font-weight: 500;
        padding: 0;
        width: auto;
        font-style: normal;
        line-height: 21px;
        text-decoration: underline;
        /* TODO: Work font sizing after discussions on how to implement. */
        /*letter-spacing: 1px;*/
        /*font-size: var(--font-size-p2);*/
        /*font-family: var(--red-hat-text);*/
    }
    .plain-text-btn .ir,
    .tertiary-btn .ir {
        display: inline-block;
    }
    .plain-text-btn:hover, .plain-text-btn:focus,
    .tertiary-btn:hover, .tertiary-btn:focus{
        text-decoration: none;
    }
    /*TODO: For future designs, CTAs will never be disabled/deactive. This is a legacy-only style. Remove in the migration.*/
    .button.deactive, .button.deactive:hover, .button.deactive:active, .button.no-shadow,
    .button.deactive, .plain-text-btn.deactive, a.deactive, .button.disabled,
    .button.deactive:hover, .plain-text-btn.deactive:hover, a.deactive:hover, .button.disabled:hover,
    .button.deactive:focus, .plain-text-btn.deactive:focus, a.deactive:focus, .button.disabled:focus{
        box-shadow: none;
        color: var(--neutral-white);
        background-color: var(--primary-medium);
        border: solid 1px var(--primary-blue);
        cursor: not-allowed;
    }
    /* TODO: */
    .tertiary-btn-dark-bg {
        color: var(--neutral-white);
    }
    
    .inline-btn {
        font-size: .9231em;
        line-height: 2.25em;
        padding: 1px 3px;
    }
 }

 
 /**
         EXTRA HEADER CLASSES:
  */
 .sub-headline-large {
     font-family: var(--domaine-display-regular);
     font-size: var(--size-subtitles-sub-headline-large);
     line-height: 29px;
     letter-spacing: 0
 }
 .sub-headline-medium {
     font-family: var(--proxima-nova-bold);
     font-size: var(--size-subtitles-sub-headline-medium);
     line-height: 26px;
     letter-spacing: 0
 }
 .sub-headline-small {
     font-family: var(--proxima-nova-bold);
     font-size: var(--size-subtitles-sub-headline-small);
     line-height: 22px;
     letter-spacing: 0;
 }
 
 
 /*!*******************************************!*/
 /*!* BUTTONS *!*/
 /*!* Default Button - Used on input, button, hrefs.*/
 
 /*    NOTE: New styling has been implemented here and all buttons as a retrofit using the PCL Master Design System Figma as of 03/20/2023.*/
 /*    It is a retrofit because the goal is to transition these, as well as all of our other global styling over to pcl-ui-core, the modernized*/
 /*    pcl front-end docroot which is built based on BEM, SASS, and Components.*/
 
 /*    For this class particularly, it's been modified to hold all styling*/
 /*    which all of our CTA buttons share, such as border-radius, and acts as a default.*/
 
 /*    Because the elements found globally which hold only the .button class are considered Secondary, Secondary CTAs act and are defined as the "default" button.*/
 /*    Also includes the .secondary-btn class for projects ready to switch over to the new class name.*/
 /* *!*/
 /*!* TODO: change to pink variables when decisions are made. *!*/
 /*.button,*/
 /*.secondary-btn {*/
 /*    !* All CTA button styling *!*/
 /*    border-radius: 100px;*/
 /*    padding: 16px 32px;*/
 /*    margin: 0;*/
 /*    text-decoration: none;*/
 /*    display: inline-flex;*/
 /*    align-items: center;*/
 /*    place-content: center;*/
 /*    white-space: nowrap;*/
 /*    cursor: pointer;*/
 /*    min-width: 148px;*/
 /*    text-underline-offset: 4px;*/
 /*    font-style: normal;*/
 /*    font-weight: 600;*/
 /*    line-height: 100%;*/
 /*    letter-spacing: 0.5px;*/
 /*    text-transform: uppercase;*/
 
 /*    !* Default Button Styling for Secondaries*!*/
 /*    border: solid 2px var(--border-button-blue-version-secondary-default);*/
 /*    color: var(--text-button-blue-version-secondary-default);*/
 /*    background: transparent;*/
 /*}*/
 /*!* .btn-sm is extension class for the small version of CTAs.*!*/
 /*.button.btn-sm {*/
 /*    padding: 14px 24px;*/
 /*}*/
 /*!* Made the selectors a couple levels more specific to take priority for various problematic styling, but still overwriteable.*!*/
 /*.web-font body .button,*/
 /*.flexbox body .button,*/
 /*.web-font body .secondary-btn {*/
 /*    font-size: var(--size-call-to-action-cta-button-text-medium);*/
 /*    text-transform: uppercase;*/
 /*    font-family: var(--proxima-nova-semi-bold);*/
 /*}*/
 /*.web-font body .button.btn-sm,*/
 /*.flexbox body .button.btn-sm {*/
 /*    font-size: var(--size-call-to-action-cta-button-text-small);*/
 /*}*/
 
 
 /*!**/
 /*    Secondary Button Dark BG - Variant of Secondary CTA Button*/
 /*    For different upcoming designs, as well as the existing "CTA Bar" found on pages like p.com/learn/cruise-destinations/alaska-cruises (at the bottom),*/
 /*    a dark-bg variant is defined here*/
 /* *!*/
 /*!* Legacy *!*/
 /*.cta-bar .button,*/
 /*.secondary-btn-dark-bg,*/
 /*    !* Modernized *!*/
 /*!* .btn-dark-bg is extension class for the small version of CTAs.*!*/
 /*.button.btn-dark-bg {*/
 /*    border: 2px solid var(--background-button-blue-version-primary-inverted-default);*/
 /*    color: var(--border-button-blue-version-secondary-inverted-default);*/
 /*}*/
 
 /*!**/
 /*    By default, all buttons signify a hover/focus with an underline. This should cover all of them, but there*/
 /*    are additional definitions in the variant blocks below to cover each variants specific hover/focus.*/
 /**!*/
 /*.button:not(.plain-text-btn):not(.primary-btn):focus,*/
 /*.button.selected:not(.plain-text-btn):not(.primary-btn):focus {*/
 /*    border: 2px solid var(--border-button-blue-version-secondary-hover);*/
 /*    text-decoration: none;*/
 /*}*/
 /*.button:not(.plain-text-btn):active,*/
 /*.selected:not(.plain-text-btn):active {*/
 /*    text-decoration: underline;*/
 /*}*/
 /*.web-font .button {*/
 /*    font-style: normal;*/
 /*    font-weight: 500;*/
 /*    line-height: 21px;*/
 /*}*/
 /*.plain-text-btn:focus, .tertiary-btn:focus,*/
 /*.button:focus, .button.selected:focus {*/
 /*    box-shadow: unset;*/
 /*    outline: 2px solid var(--neutral-black);*/
 /*    outline-offset: 2px;*/
 /*}*/
 /*.button:active, .button.selected {*/
 /*    text-decoration: underline;*/
 /*}*/
 /*!* CTA (Call to Action) Button Variants *!*/
 /*!**/
 /*    Retrofit of all of the old .[color]-btn classes with new cta button colors, styles, and variants.*/
 
 /*    NOTE: A challenge to be aware of is that, in the new design standards we have primary, secondary, and tertiary buttons defined*/
 /*    with variants, like small/large dark-bg/light-bg. In the new ui-core, these will be more precisely defined and implemented in BEM, with nested modifiers.*/
 /*    For legacy, the naming and selectors might be a little more difficult to understand, since we're in transition.*/
 /* *!*/
 /*!* Base Primary CTA -*/
 /*    In most locations of the new designs,*/
 /*    the Primary CTA button design will be implemented.*/
 /*    Included here is the new class name, which will eventually be implemented everywhere, .primary-btn.*/
 /**!*/
 /*!* Legacy *!*/
 /*.green-btn, .green-btn.selected,*/
 /*.gray-btn, .gray-btn.selected,*/
 /*.blue-btn, .blue-btn.selected,*/
 /*.red-btn, .red-btn.selected,*/
 /*    !* Modernized *!*/
 /*.primary-btn, .primary-btn.selected {*/
 /*    color: var(--text-button-blue-version-primary-default);*/
 /*    background: var(--background-button-blue-version-primary-default);*/
 /*    font-family: var(--proxima-nova-bold);*/
 /*}*/
 
 /*!**/
 /*    Primary CTA Base Dark BG Variant -*/
 /**!*/
 /*!* LEGACY*!*/
 /*.green-btn.btn-dark-bg,.green-btn.selected,*/
 /*.white-btn, .white-btn.selected,*/
 /*a:hover .white-btn, a:focus .white-btn,*/
 /*a:hover .white-btn.selected, a:focus .white-btn.selected,*/
 /*.primary-btn-dark-bg, .primary-btn-dark-bg.selected,*/
 /*.green-btn.btn-dark-bg:hover,.green-btn.selected:hover,*/
 /*.primary-btn-dark-bg:hover, .primary-btn-dark-bg.selected:hover,*/
 /*!* MODERNIZED *!*/
 /*.primary-btn.btn-dark-bg:hover,*/
 /*.primary-btn.btn-dark-bg:focus {*/
 /*    background: var(--background-button-blue-version-primary-inverted-hover);*/
 /*    color: var(--text-button-blue-version-primary-inverted-hover);*/
 /*    border: 2px solid var(--background-button-blue-version-primary-inverted-hover);*/
 /*}*/
 /*.primary-btn.btn-dark-bg:focus {*/
 /*    box-shadow: unset;*/
 /*    outline: 2px solid var(--background-button-blue-version-primary-inverted-hover);*/
 /*    outline-offset: 2px;*/
 /*}*/
 
 /*!* Primary Cta Hover & Focus *!*/
 /*!* Legacy *!*/
 /*.green-btn:hover, .green-btn:focus, .green-btn.selected:hover, .green-btn.selected:focus,*/
 /*.gray-btn:hover, .gray-btn:focus, .gray-btn.selected:hover, .gray-btn.selected:focus*/
 /*.blue-btn:hover, .blue-btn:focus, .blue-btn.selected:hover, .blue-btn.selected:focus,*/
 /*.red-btn:hover, .red-btn:focus, .red-btn.selected:hover, .red-btn.selected:focus, .red-btn.selected:hover, .red-btn.selected:focus,*/
 /*    !* Modernized *!*/
 /*.primary-btn:hover, .primary-btn:focus, .primary-btn.selected:hover, .primary-btn.selected:focus {*/
 /*    background: var(--background-button-blue-version-primary-hover);*/
 /*    !*border: unset;*!*/
 /*}*/
 
 /*!* Primary Cta Deactive State -*/
 /*   TODO: For future designs, CTAs will never be disabled/deactive. This is a legacy-only style. Remove in the migration.*/
 /* *!*/
 /*!* Legacy *!*/
 /*.green-btn.deactive, .green-btn.selected.deactive, .green-btn.deactive:hover, .green-btn.deactive:focus,*/
 /*.gray-btn.deactive, .gray-btn.deactive:hover, .gray-btn.deactive:focus,*/
 /*.blue-btn.deactive, .blue-btn.deactive:hover, .blue-btn.deactive:focus,*/
 /*.red-btn.deactive, .red-btn.deactive:hover, .red-btn.deactive:focus,*/
 /*.white-btn.deactive, .white-btn.deactive:hover, .white-btn.deactive:focus,*/
 /*a.deactive:hover .white-btn, a.deactive:focus,*/
 /*    !* Modernized *!*/
 /*.primary-btn.deactive, .primary-btn.selected.deactive, .primary-btn.deactive:hover, .primary-btn.deactive:focus{*/
 /*    background: var(--background-button-blue-version-primary-disabled);*/
 /*    color: var(--text-button-blue-version-primary-disabled);*/
 /*    cursor: not-allowed;*/
 /*}*/
 /*!**/
 /*    Tertiary, Links, and Hyperlinks*/
 
 /*    NOTE: All three of these buttons start with a base class of "plain-text-btn",*/
 /*    then, based on context, an additional class must be added. being either*/
 /*    .tertiary-btn, .link-btn, or .hyperlink-btn*/
 /*    Documentation for this can be found here: https://www.figma.com/design/1oUwwVKMsLAzqVlO8E2teQ/Web-Design-System-2024?node-id=3542-693&m=dev*/
 /**!*/
 /*.plain-text-btn {*/
 /*    background: transparent;*/
 /*    border: none;*/
 /*    !* BUTTONS TODO: if global wants new buttons, switch these-- *!*/
 /*    !*color: var(--primary-blue);*!*/
 /*    color: var(--text-button-blue-version-link-default);*/
 
 /*    font-size: var(--size-call-to-action-tertiary-cta-button-text);*/
 /*    padding: 0;*/
 /*    margin: 0;*/
 /*    width: auto;*/
 /*    font-style: normal;*/
 /*    line-height: 100%;*/
 /*    text-decoration: none;*/
 /*    text-underline-offset: 4px;*/
 /*    letter-spacing: 0;*/
 /*    font-family: var(--proxima-nova);*/
 /*}*/
 /*.plain-text-btn:hover, .plain-text-btn:focus {*/
 /*    text-decoration: underline;*/
 /*}*/
 /*!* Tertiary *!*/
 /*.tertiary-btn {*/
 /*    border-radius: 100px;*/
 /*    padding: 4px 0px 4px 2px;*/
 /*    font-weight: 600;*/
 /*    line-height: 150%;*/
 /*}*/
 /*!* Link *!*/
 /*.link-btn {*/
 /*    text-transform: uppercase;
 /*    font-weight: 600;*/
 /*    font-family: var(--proxima-nova-semi-bold);*/
 /*    letter-spacing: 0.5px;*/
 /*    line-height: 110%;*/
 /*}*/
 /*.link-btn:hover, .link-btn:focus {*/
 
 /*}*/
 /*!* TODO: IDEAS FOR ANIMATING UNDERLINE *!*/
 /*!*.tertiary-btn:after,*!*/
 /*!*.link-btn:after {*!*/
 /*!*    background: none repeat scroll 0 0 transparent;*!*/
 /*!*    bottom: 0;*!*/
 /*!*    content: "";*!*/
 /*!*    display: block;*!*/
 /*!*    height: 1px;*!*/
 /*!*    left: 0px;*!*/
 /*!*    position: absolute;*!*/
 /*!*    background: #1C1F22;*!*/
 /*!*    transition: width 0.3s ease 0s, left 0.3s ease 0s;*!*/
 /*!*    width: 10px;*!*/
 /*!*}*!*/
 /*!*.tertiary-btn:hover:after,*!*/
 /*!*.link-btn:hover:after {*!*/
 /*!*    width: 40px;*!*/
 /*!*    left: 0px;*!*/
 /*!*    background: #353694;*!*/
 /*!*}*!*/
 /*!* Hyperlink (generally used for inside paragraphs) *!*/
 /*.hyperlink-btn {*/
 /*    text-decoration: underline;*/
 /*    font-family: var(--proxima-nova-semi-bold);*/
 /*    line-height: 100%;*/
 /*}*/
 /*.hyperlink-btn:hover, .hyperlink-btn:focus {*/
 /*    text-decoration: none;*/
 /*}*/
 /*!* unsure what this is for. *!*/
 /*.plain-text-btn .ir,*/
 /*.tertiary-btn .ir {*/
 /*    display: inline-block;*/
 /*}*/
 /*.button.deactive, .button.deactive:hover, .button.deactive:active, .button.no-shadow,*/
 /*.button.deactive, .plain-text-btn.deactive, a.deactive, .button.disabled,*/
 /*.button.deactive:hover, .plain-text-btn.deactive:hover, a.deactive:hover, .button.disabled:hover,*/
 /*.button.deactive:focus, .plain-text-btn.deactive:focus, a.deactive:focus, .button.disabled:focus{*/
 /*    box-shadow: none;*/
 /*    color: var(--text-button-blue-version-secondary-disabled);*/
 /*    background-color: var(--background-button-blue-version-primary-disabled);*/
 /*    border: solid 2px var(--border-button-blue-version-secondary-disabled);*/
 /*    cursor: not-allowed;*/
 /*}*/
 /*!* TODO: *!*/
 /*.tertiary-btn-dark-bg {*/
 /*    color: var(--text-button-blue-version-tertiary-inverted-default);*/
 /*}*/
 /*.inline-btn {*/
 /*    font-size: .9231em;*/
 /*    line-height: 2.25em;*/
 /*    padding: 1px 3px;*/
 /*}*/
 
 /* Flex Classes */
 .flex {
     display: flex;
 }
 .justify-content-space-between {
     justify-content: space-between;
 }
 .full-width {
     width: 100%;
 }
 .full-height {
     height: 100%;
 }
 .gap-8 {
     gap: 8px;
 }
 .gap-16 {
     gap: 16px
 }
 .gap-20 {
     gap: 20px
 }
 .gap-40 {
     gap: 40px;
 }
 .align-items-center {
     align-items: center;
 }
 .line-through {
     text-decoration: line-through;
 }
 
 /* colors.css */
 .auxiliary-red {
     color: var(--auxiliary-red);
 }
 .auxiliary-red-bg {
     background-color: var(--auxiliary-red);
 }
 .auxiliary-auxiliary-red-border {
     border-color: var(--auxiliary-red);
 }
 .auxiliary-green {
     color: var(--auxiliary-green);
 }
 
 /* positioning-spacing.css */
 .d-inline-block {
     display: inline-block;
 }
 .d-block {
     display: block;
 }
 .d-inline {
     display: inline;
 }
 .pull-top-10 {
     margin-top: -10px;
 }
 .margin-auto {
     margin: auto;
 }
 .pointer-events-none {
     pointer-events: none;
 }
 /* flex.css */
 .d-flex {
     display: flex;
 }
 .d-flex-column {
     display: flex;
     flex-direction: column;
 }
 .flex-wrap-wrap {
     flex-wrap: wrap;
 }
 .flex-dir-row {
     flex-direction: row;
 }
 .flex-dir-col {
     flex-direction: column;
 }
 .flex-dir-row-rev {
     flex-direction: row-reverse;
 }
 .justify-items-center {
     justify-items: center;
 }
 .align-self-center {
     align-self: center;
 }
 .justify-content-center {
     justify-content: center;
 }
 .justify-content-left {
     justify-content: left;
 }
 .justify-content-right {
     justify-content: right;
 }
 .align-items-baseline {
     align-items: baseline;
 }
 .place-content-center {
     place-content: center;
 }
 .place-self-center {
     place-self: center;
 }
 .place-content-space-between {
     place-content: space-between;
 }
 .gap-4 {
     gap: 4px;
 }
 .gap-10 {
     gap: 10px;
 }
 .gap-12 {
     gap: 12px;
 }
 .gap-24 {
     gap: 24px;
 }
 .gap-32 {
     gap: 32px;
 }
 
 .left-spacing-16 {
     margin-left: 16px;
 }
 /* text.css */
 .text-capitalize {
     text-transform: capitalize;
 }
 .underline-hover-focus:hover,
 .underline-hover-focus:focus {
     text-decoration: underline;
 }
 .title-case {
     text-transform: capitalize;
 }
 .button-underline {
     text-decoration: underline;
 }
 .button-underline:hover {
     text-decoration: none;
 }
 .position-relative {
     position: relative;
 }
 .svg-small {
     height: 12px;
     margin-top: -5px;
 }
 .svg-green {
     color: var(--auxiliary-green);
 }
 .svg-standard {
     height: 16px;
     width: 16px;
 }
 
 .top-rounded-corners {
     border-radius: 10px 10px 0 0;
 }
 .bottom-rounded-corners {
     border-radius: 0 0 10px 10px;
 }
 .rounded-corners-light {
     border-radius: 4px;
 }
 .btm-box-shadow {
     box-shadow: 0px 5px 6px var(--transparent-medium);
 }
 .border-radius-4 {
     border-radius: 4px;
 }
 .border-radius-8 {
     border-radius: 8px;
 }
 .red, .required, .required-field sup.required, .error, legend .required {
     color: var(--error);
 }
 input.error, select.error {
     border-color: var(--error);
 }
 .no-focus-outline {
     outline: none;
 }
 .display-table-cell {
     display: table-cell;
 }
 .warning-bg {
     background-color: var(--error-bg);
 }
 .white-select {
     background-color: var(--neutral-white);
 }
 
 .white-space-normal {
     white-space: normal;
 }
 .width-50 {
     width: 50%;
 }
 
 /**
             Spacing, Margin, Padding, Etc.
  */
 /* responsive column grid - to be used only on new rwd projects */
 .col-xs-1,
 .col-xs-2,
 .col-xs-3,
 .col-xs-4,
 .col-xs-5,
 .col-xs-6,
 .col-xs-7,
 .col-xs-8,
 .col-xs-9,
 .col-xs-10,
 .col-xs-11,
 .col-xs-12,
 .col-sm-1,
 .col-sm-2,
 .col-sm-3,
 .col-sm-4,
 .col-sm-5,
 .col-sm-6,
 .col-sm-7,
 .col-sm-8,
 .col-sm-9,
 .col-sm-10,
 .col-sm-11,
 .col-sm-12,
 .col-md-1,
 .col-md-2,
 .col-md-3,
 .col-md-4,
 .col-md-5,
 .col-md-6,
 .col-md-7,
 .col-md-8,
 .col-md-9,
 .col-md-10,
 .col-md-11,
 .col-md-12,
 .col-lg-1,
 .col-lg-2,
 .col-lg-3,
 .col-lg-4,
 .col-lg-5,
 .col-lg-6,
 .col-lg-7,
 .col-lg-8,
 .col-lg-9,
 .col-lg-10,
 .col-lg-11,
 .col-lg-12 {
     position: relative;
     min-height: 1px;
     padding-right: 15px;
     padding-left: 15px;
 }
 
 /**
  * These values will not show up in content, but can be
  * queried by JavaScript to know which breakpoint is active.
  * Add or remove as many breakpoints as you like.
  * https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
  * https://davidwalsh.name/pseudo-element
  */
 .rwd:before {
     content: "xs";
     display: none; /* Prevent from displaying. */
 }
 .col-xs-1,
 .col-xs-2,
 .col-xs-3,
 .col-xs-4,
 .col-xs-5,
 .col-xs-6,
 .col-xs-7,
 .col-xs-8,
 .col-xs-9,
 .col-xs-10,
 .col-xs-11 {
     float: left;
 }
 .col-xs-1 {
     width: 8.333333333333332%;
 }
 .col-xs-2 {
     width: 16.666666666666664%;
 }
 .col-xs-3 {
     width: 25%;
 }
 .col-xs-4 {
     width: 33.33333333333333%;
 }
 .col-xs-5 {
     width: 41.66666666666667%;
 }
 .col-xs-6 {
     width: 50%;
 }
 .col-xs-7 {
     width: 58.333333333333336%;
 }
 .col-xs-8 {
     width: 66.66666666666666%;
 }
 .col-xs-9 {
     width: 75%;
 }
 .col-xs-10 {
     width: 83.33333333333334%;
 }
 .col-xs-11 {
     width: 91.66666666666666%;
 }
 .col-xs-12 {
     width: 100%;
 }
 .container {
     margin: 0 auto;
 }
 .col-xs-push-1 {
     left: 8.333333333333332%;
 }
 .col-xs-push-2 {
     left: 16.666666666666664%;
 }
 .col-xs-push-3 {
     left: 25%;
 }
 .col-xs-push-4 {
     left: 33.33333333333333%;
 }
 .col-xs-push-5 {
     left: 41.66666666666667%;
 }
 .col-xs-push-6 {
     left: 50%;
 }
 .col-xs-push-7 {
     left: 58.333333333333336%;
 }
 .col-xs-push-8 {
     left: 66.66666666666666%;
 }
 .col-xs-push-9 {
     left: 75%;
 }
 .col-xs-push-10 {
     left: 83.33333333333334%;
 }
 .col-xs-push-11 {
     left: 91.66666666666666%;
 }
 .col-xs-pull-1 {
     right: 8.333333333333332%;
 }
 .col-xs-pull-2 {
     right: 16.666666666666664%;
 }
 .col-xs-pull-3 {
     right: 25%;
 }
 .col-xs-pull-4 {
     right: 33.33333333333333%;
 }
 .col-xs-pull-5 {
     right: 41.66666666666667%;
 }
 .col-xs-pull-6 {
     right: 50%;
 }
 .col-xs-pull-7 {
     right: 58.333333333333336%;
 }
 .col-xs-pull-8 {
     right: 66.66666666666666%;
 }
 .col-xs-pull-9 {
     right: 75%;
 }
 .col-xs-pull-10 {
     right: 83.33333333333334%;
 }
 .col-xs-pull-11 {
     right: 91.66666666666666%;
 }
 .col-xs-offset-1 {
     margin-left: 8.333333333333332%;
 }
 .col-xs-offset-2 {
     margin-left: 16.666666666666664%;
 }
 .col-xs-offset-3 {
     margin-left: 25%;
 }
 .col-xs-offset-4 {
     margin-left: 33.33333333333333%;
 }
 .col-xs-offset-5 {
     margin-left: 41.66666666666667%;
 }
 .col-xs-offset-6 {
     margin-left: 50%;
 }
 .col-xs-offset-7 {
     margin-left: 58.333333333333336%;
 }
 .col-xs-offset-8 {
     margin-left: 66.66666666666666%;
 }
 .col-xs-offset-9 {
     margin-left: 75%;
 }
 .col-xs-offset-10 {
     margin-left: 83.33333333333334%;
 }
 .col-xs-offset-11 {
     margin-left: 91.66666666666666%;
 }
 .col-xs-push-1 {
     left: 8.333333333333332%;
 }
 .col-xs-push-2 {
     left: 16.666666666666664%;
 }
 .col-xs-push-3 {
     left: 25%;
 }
 .col-xs-push-4 {
     left: 33.33333333333333%;
 }
 .col-xs-push-5 {
     left: 41.66666666666667%;
 }
 .col-xs-push-6 {
     left: 50%;
 }
 .col-xs-push-7 {
     left: 58.333333333333336%;
 }
 .col-xs-push-8 {
     left: 66.66666666666666%;
 }
 .col-xs-push-9 {
     left: 75%;
 }
 .col-xs-push-10 {
     left: 83.33333333333334%;
 }
 .col-xs-push-11 {
     left: 91.66666666666666%;
 }
 .col-xs-pull-1 {
     right: 8.333333333333332%;
 }
 .col-xs-pull-2 {
     right: 16.666666666666664%;
 }
 .col-xs-pull-3 {
     right: 25%;
 }
 .col-xs-pull-4 {
     right: 33.33333333333333%;
 }
 .col-xs-pull-5 {
     right: 41.66666666666667%;
 }
 .col-xs-pull-6 {
     right: 50%;
 }
 .col-xs-pull-7 {
     right: 58.333333333333336%;
 }
 .col-xs-pull-8 {
     right: 66.66666666666666%;
 }
 .col-xs-pull-9 {
     right: 75%;
 }
 .col-xs-pull-10 {
     right: 83.33333333333334%;
 }
 .col-xs-pull-11 {
     right: 91.66666666666666%;
 }
 .col-xs-offset-1 {
     margin-left: 8.333333333333332%;
 }
 .col-xs-offset-2 {
     margin-left: 16.666666666666664%;
 }
 .col-xs-offset-3 {
     margin-left: 25%;
 }
 .col-xs-offset-4 {
     margin-left: 33.33333333333333%;
 }
 .col-xs-offset-5 {
     margin-left: 41.66666666666667%;
 }
 .col-xs-offset-6 {
     margin-left: 50%;
 }
 .col-xs-offset-7 {
     margin-left: 58.333333333333336%;
 }
 .col-xs-offset-8 {
     margin-left: 66.66666666666666%;
 }
 .col-xs-offset-9 {
     margin-left: 75%;
 }
 .col-xs-offset-10 {
     margin-left: 83.33333333333334%;
 }
 .col-xs-offset-11 {
     margin-left: 91.66666666666666%;
 }
 .col-xs-pad-0, .col-xs-pad-0-left {
     padding-left: 0;
 }
 .col-xs-pad-0, .col-xs-pad-0-right {
     padding-right: 0;
 }
 .col-xs-pad-0-top {
     padding-top: 0;
 }
 .col-xs-pad-0-bottom {
     padding-bottom: 0;
 }
 .col-xs-pad-5, .col-xs-pad-5-left {
     padding-left: 5px;
 }
 .col-xs-pad-5, .col-xs-pad-5-right {
     padding-right: 5px;
 }
 .col-xs-pad-5-top {
     padding-top: 5px;
 }
 .col-xs-pad-5-bottom {
     padding-bottom: 5px;
 }
 .col-xs-pad-10, .col-xs-pad-10-left {
     padding-left: 10px;
 }
 .col-xs-pad-10, .col-xs-pad-10-right {
     padding-right: 10px;
 }
 .col-xs-pad-10-top {
     padding-top: 10px;
 }
 .col-xs-pad-10-bottom {
     padding-bottom: 10px;
 }
 .col-xs-pad-15, .col-xs-pad-15-left {
     padding-left: 15px;
 }
 .col-xs-pad-15, .col-xs-pad-15-right {
     padding-right: 15px;
 }
 .col-xs-pad-15-top {
     padding-top: 15px;
 }
 .col-xs-pad-15-bottom {
     padding-bottom: 15px;
 }
 .col-xs-pad-20, .col-xs-pad-20-left {
     padding-left: 20px;
 }
 .col-xs-pad-20, .col-xs-pad-20-right {
     padding-right: 20px;
 }
 .col-xs-pad-20-top {
     padding-top: 20px;
 }
 .col-xs-pad-20-bottom {
     padding-bottom: 20px;
 }
 .col-xs-pad-25, .col-xs-pad-25-left {
     padding-left: 25px;
 }
 .col-xs-pad-25, .col-xs-pad-25-right {
     padding-right: 25px;
 }
 .col-xs-pad-25-top {
     padding-top: 25px;
 }
 .col-xs-pad-25-bottom {
     padding-bottom: 25px;
 }
 .col-xs-pad-30, .col-xs-pad-30-left {
     padding-left: 30px;
 }
 .col-xs-pad-30, .col-xs-pad-30-right {
     padding-right: 30px;
 }
 .col-xs-pad-30-top {
     padding-top: 30px;
 }
 .col-xs-pad-30-bottom {
     padding-bottom: 30px;
 }
 .col-xs-pad-35, .col-xs-pad-35-left {
     padding-left: 35px;
 }
 .col-xs-pad-35, .col-xs-pad-35-right {
     padding-right: 35px;
 }
 .col-xs-pad-35-top {
     padding-top: 35px;
 }
 .col-xs-pad-35-bottom {
     padding-bottom: 35px;
 }
 .col-xs-pad-40, .col-xs-pad-40-left {
     padding-left: 40px;
 }
 .col-xs-pad-40, .col-xs-pad-40-right {
     padding-right: 40px;
 }
 .col-xs-pad-40-top {
     padding-top: 40px;
 }
 .col-xs-pad-40-bottom {
     padding-bottom: 40px;
 }
 .col-xs-pad-45, .col-xs-pad-45-left {
     padding-left: 45px;
 }
 .col-xs-pad-45, .col-xs-pad-45-right {
     padding-right: 45px;
 }
 .col-xs-pad-45-top {
     padding-top: 45px;
 }
 .col-xs-pad-45-bottom {
     padding-bottom: 45px;
 }
 .col-xs-pad-50, .col-xs-pad-50-left {
     padding-left: 50px;
 }
 .col-xs-pad-50, .col-xs-pad-50-right {
     padding-right: 50px;
 }
 .col-xs-pad-50-top {
     padding-top: 50px;
 }
 .col-xs-pad-50-bottom {
     padding-bottom: 50px;
 }
 .col-xs-margin-0, .col-xs-margin-0-left {
     margin-left: 0;
 }
 .col-xs-margin-0, .col-xs-margin-0-right {
     margin-right: 0;
 }
 .col-xs-margin-0-top {
     margin-top: 0;
 }
 .col-xs-margin-0-bottom {
     margin-bottom: 0;
 }
 .col-xs-margin-5, .col-xs-margin-5-left {
     margin-left: 5px;
 }
 .col-xs-margin-5, .col-xs-margin-5-right {
     margin-right: 5px;
 }
 .col-xs-margin-5-top {
     margin-top: 5px;
 }
 .col-xs-margin-5-bottom {
     margin-bottom: 5px;
 }
 .col-xs-margin-10, .col-xs-margin-10-left {
     margin-left: 10px;
 }
 .col-xs-margin-10, .col-xs-margin-10-right {
     margin-right: 10px;
 }
 .col-xs-margin-10-top {
     margin-top: 10px;
 }
 .col-xs-margin-10-bottom {
     margin-bottom: 10px;
 }
 .col-xs-margin-15, .col-xs-margin-15-left {
     margin-left: 15px;
 }
 .col-xs-margin-15, .col-xs-margin-15-right {
     margin-right: 15px;
 }
 .col-xs-margin-15-top {
     margin-top: 15px;
 }
 .col-xs-margin-15-bottom {
     margin-bottom: 15px;
 }
 .col-xs-margin-20, .col-xs-margin-20-left {
     margin-left: 20px;
 }
 .col-xs-margin-20, .col-xs-margin-20-right {
     margin-right: 20px;
 }
 .col-xs-margin-20-top {
     margin-top: 20px;
 }
 .col-xs-margin-20-bottom {
     margin-bottom: 20px;
 }
 .col-xs-margin-25, .col-xs-margin-25-left {
     margin-left: 25px;
 }
 .col-xs-margin-25, .col-xs-margin-25-right {
     margin-right: 25px;
 }
 .col-xs-margin-25-top {
     margin-top: 25px;
 }
 .col-xs-margin-25-bottom {
     margin-bottom: 25px;
 }
 .col-xs-margin-30, .col-xs-margin-30-left {
     margin-left: 30px;
 }
 .col-xs-margin-30, .col-xs-margin-30-right {
     margin-right: 30px;
 }
 .col-xs-margin-30-top {
     margin-top: 30px;
 }
 .col-xs-margin-30-bottom {
     margin-bottom: 30px;
 }
 .col-xs-margin-35, .col-xs-margin-35-left {
     margin-left: 35px;
 }
 .col-xs-margin-35, .col-xs-margin-35-right {
     margin-right: 35px;
 }
 .col-xs-margin-35-top {
     margin-top: 35px;
 }
 .col-xs-margin-35-bottom {
     margin-bottom: 35px;
 }
 .col-xs-margin-40, .col-xs-margin-40-left {
     margin-left: 40px;
 }
 .col-xs-margin-40, .col-xs-margin-40-right {
     margin-right: 40px;
 }
 .col-xs-margin-40-top {
     margin-top: 40px;
 }
 .col-xs-margin-40-bottom {
     margin-bottom: 40px;
 }
 .col-xs-margin-45, .col-xs-margin-45-left {
     margin-left: 45px;
 }
 .col-xs-margin-45, .col-xs-margin-45-right {
     margin-right: 45px;
 }
 .col-xs-margin-45-top {
     margin-top: 45px;
 }
 .col-xs-margin-45-bottom {
     margin-bottom: 45px;
 }
 .col-xs-margin-50, .col-xs-margin-50-left {
     margin-left: 50px;
 }
 .col-xs-margin-50, .col-xs-margin-50-right {
     margin-right: 50px;
 }
 .col-xs-margin-50-top {
     margin-top: 50px;
 }
 .col-xs-margin-50-bottom {
     margin-bottom: 50px;
 }
 
 
 /*change 768px to 640px to accommodate a new breakpoint*/
 @media (min-width:40em) {
     .rwd:before {
         content: "sm";
     }
     .container {
         max-width: 768px; /*990px;*/ /*750px;*/
     }
     .col-sm-1,
     .col-sm-2,
     .col-sm-3,
     .col-sm-4,
     .col-sm-5,
     .col-sm-6,
     .col-sm-7,
     .col-sm-8,
     .col-sm-9,
     .col-sm-10,
     .col-sm-11 {
         float: left;
     }
     .col-sm-1 {
         width: 8.333333333333332%;
     }
     .col-sm-2 {
         width: 16.666666666666664%;
     }
     .col-sm-3 {
         width: 25%;
     }
     .col-sm-4 {
         width: 33.33333333333333%;
     }
     .col-sm-5 {
         width: 41.66666666666667%;
     }
     .col-sm-6 {
         width: 50%;
     }
     .col-sm-7 {
         width: 58.333333333333336%;
     }
     .col-sm-8 {
         width: 66.66666666666666%;
     }
     .col-sm-9 {
         width: 75%;
     }
     .col-sm-10 {
         width: 83.33333333333334%;
     }
     .col-sm-11 {
         width: 91.66666666666666%;
     }
     .col-sm-12 {
         width: 100%;
     }
     .col-sm-push-0 {
         left: auto;
     }
     .col-sm-push-1 {
         left: 8.333333333333332%;
     }
     .col-sm-push-2 {
         left: 16.666666666666664%;
     }
     .col-sm-push-3 {
         left: 25%;
     }
     .col-sm-push-4 {
         left: 33.33333333333333%;
     }
     .col-sm-push-5 {
         left: 41.66666666666667%;
     }
     .col-sm-push-6 {
         left: 50%;
     }
     .col-sm-push-7 {
         left: 58.333333333333336%;
     }
     .col-sm-push-8 {
         left: 66.66666666666666%;
     }
     .col-sm-push-9 {
         left: 75%;
     }
     .col-sm-push-10 {
         left: 83.33333333333334%;
     }
     .col-sm-push-11 {
         left: 91.66666666666666%;
     }
     .col-sm-pull-0 {
         right: auto;
     }
     .col-sm-pull-1 {
         right: 8.333333333333332%;
     }
     .col-sm-pull-2 {
         right: 16.666666666666664%;
     }
     .col-sm-pull-3 {
         right: 25%;
     }
     .col-sm-pull-4 {
         right: 33.33333333333333%;
     }
     .col-sm-pull-5 {
         right: 41.66666666666667%;
     }
     .col-sm-pull-6 {
         right: 50%;
     }
     .col-sm-pull-7 {
         right: 58.333333333333336%;
     }
     .col-sm-pull-8 {
         right: 66.66666666666666%;
     }
     .col-sm-pull-9 {
         right: 75%;
     }
     .col-sm-pull-10 {
         right: 83.33333333333334%;
     }
     .col-sm-pull-11 {
         right: 91.66666666666666%;
     }
     .col-sm-offset-0 {
         margin-left: 0;
     }
     .col-sm-offset-1 {
         margin-left: 8.333333333333332%;
     }
     .col-sm-offset-2 {
         margin-left: 16.666666666666664%;
     }
     .col-sm-offset-3 {
         margin-left: 25%;
     }
     .col-sm-offset-4 {
         margin-left: 33.33333333333333%;
     }
     .col-sm-offset-5 {
         margin-left: 41.66666666666667%;
     }
     .col-sm-offset-6 {
         margin-left: 50%;
     }
     .col-sm-offset-7 {
         margin-left: 58.333333333333336%;
     }
     .col-sm-offset-8 {
         margin-left: 66.66666666666666%;
     }
     .col-sm-offset-9 {
         margin-left: 75%;
     }
     .col-sm-offset-10 {
         margin-left: 83.33333333333334%;
     }
     .col-sm-offset-11 {
         margin-left: 91.66666666666666%;
     }
     .col-sm-pad-0, .col-sm-pad-0-left {
         padding-left: 0;
     }
     .col-sm-pad-0, .col-sm-pad-0-right {
         padding-right: 0;
     }
     .col-sm-pad-0-top {
         padding-top: 0;
     }
     .col-sm-pad-0-bottom {
         padding-bottom: 0;
     }
     .col-sm-pad-5, .col-sm-pad-5-left {
         padding-left: 5px;
     }
     .col-sm-pad-5, .col-sm-pad-5-right {
         padding-right: 5px;
     }
     .col-sm-pad-5-top {
         padding-top: 5px;
     }
     .col-sm-pad-5-bottom {
         padding-bottom: 5px;
     }
     .col-sm-pad-10, .col-sm-pad-10-left {
         padding-left: 10px;
     }
     .col-sm-pad-10, .col-sm-pad-10-right {
         padding-right: 10px;
     }
     .col-sm-pad-10-top {
         padding-top: 10px;
     }
     .col-sm-pad-10-bottom {
         padding-bottom: 10px;
     }
     .col-sm-pad-15, .col-sm-pad-15-left {
         padding-left: 15px;
     }
     .col-sm-pad-15, .col-sm-pad-15-right {
         padding-right: 15px;
     }
     .col-sm-pad-15-top {
         padding-top: 15px;
     }
     .col-sm-pad-15-bottom {
         padding-bottom: 15px;
     }
     .col-sm-pad-20, .col-sm-pad-20-left {
         padding-left: 20px;
     }
     .col-sm-pad-20, .col-sm-pad-20-right {
         padding-right: 20px;
     }
     .col-sm-pad-20-top {
         padding-top: 20px;
     }
     .col-sm-pad-20-bottom {
         padding-bottom: 20px;
     }
     .col-sm-pad-25, .col-sm-pad-25-left {
         padding-left: 25px;
     }
     .col-sm-pad-25, .col-sm-pad-25-right {
         padding-right: 25px;
     }
     .col-sm-pad-25-top {
         padding-top: 25px;
     }
     .col-sm-pad-25-bottom {
         padding-bottom: 25px;
     }
     .col-sm-pad-30, .col-sm-pad-30-left {
         padding-left: 30px;
     }
     .col-sm-pad-30, .col-sm-pad-30-right {
         padding-right: 30px;
     }
     .col-sm-pad-30-top {
         padding-top: 30px;
     }
     .col-sm-pad-30-bottom {
         padding-bottom: 30px;
     }
     .col-sm-pad-35, .col-sm-pad-35-left {
         padding-left: 35px;
     }
     .col-sm-pad-35, .col-sm-pad-35-right {
         padding-right: 35px;
     }
     .col-sm-pad-35-top {
         padding-top: 35px;
     }
     .col-sm-pad-35-bottom {
         padding-bottom: 35px;
     }
     .col-sm-pad-40, .col-sm-pad-40-left {
         padding-left: 40px;
     }
     .col-sm-pad-40, .col-sm-pad-40-right {
         padding-right: 40px;
     }
     .col-sm-pad-40-top {
         padding-top: 40px;
     }
     .col-sm-pad-40-bottom {
         padding-bottom: 40px;
     }
     .col-sm-pad-45, .col-sm-pad-45-left {
         padding-left: 45px;
     }
     .col-sm-pad-45, .col-sm-pad-45-right {
         padding-right: 45px;
     }
     .col-sm-pad-45-top {
         padding-top: 45px;
     }
     .col-sm-pad-45-bottom {
         padding-bottom: 45px;
     }
     .col-sm-pad-50, .col-sm-pad-50-left {
         padding-left: 50px;
     }
     .col-sm-pad-50, .col-sm-pad-50-right {
         padding-right: 50px;
     }
     .col-sm-pad-50-top {
         padding-top: 50px;
     }
     .col-sm-pad-50-bottom {
         padding-bottom: 50px;
     }
     .col-sm-margin-0, .col-sm-margin-0-left {
         margin-left: 0;
     }
     .col-sm-margin-0, .col-sm-margin-0-right {
         margin-right: 0;
     }
     .col-sm-margin-0-top {
         margin-top: 0;
     }
     .col-sm-margin-0-bottom {
         margin-bottom: 0;
     }
     .col-sm-margin-5, .col-sm-margin-5-left {
         margin-left: 5px;
     }
     .col-sm-margin-5, .col-sm-margin-5-right {
         margin-right: 5px;
     }
     .col-sm-margin-5-top {
         margin-top: 5px;
     }
     .col-sm-margin-5-bottom {
         margin-bottom: 5px;
     }
     .col-sm-margin-10, .col-sm-margin-10-left {
         margin-left: 10px;
     }
     .col-sm-margin-10, .col-sm-margin-10-right {
         margin-right: 10px;
     }
     .col-sm-margin-10-top {
         margin-top: 10px;
     }
     .col-sm-margin-10-bottom {
         margin-bottom: 10px;
     }
     .col-sm-margin-15, .col-sm-margin-15-left {
         margin-left: 15px;
     }
     .col-sm-margin-15, .col-sm-margin-15-right {
         margin-right: 15px;
     }
     .col-sm-margin-15-top {
         margin-top: 15px;
     }
     .col-sm-margin-15-bottom {
         margin-bottom: 15px;
     }
     .col-sm-margin-20, .col-sm-margin-20-left {
         margin-left: 20px;
     }
     .col-sm-margin-20, .col-sm-margin-20-right {
         margin-right: 20px;
     }
     .col-sm-margin-20-top {
         margin-top: 20px;
     }
     .col-sm-margin-20-bottom {
         margin-bottom: 20px;
     }
     .col-sm-margin-25, .col-sm-margin-25-left {
         margin-left: 25px;
     }
     .col-sm-margin-25, .col-sm-margin-25-right {
         margin-right: 25px;
     }
     .col-sm-margin-25-top {
         margin-top: 25px;
     }
     .col-sm-margin-25-bottom {
         margin-bottom: 25px;
     }
     .col-sm-margin-30, .col-sm-margin-30-left {
         margin-left: 30px;
     }
     .col-sm-margin-30, .col-sm-margin-30-right {
         margin-right: 30px;
     }
     .col-sm-margin-30-top {
         margin-top: 30px;
     }
     .col-sm-margin-30-bottom {
         margin-bottom: 30px;
     }
     .col-sm-margin-35, .col-sm-margin-35-left {
         margin-left: 35px;
     }
     .col-sm-margin-35, .col-sm-margin-35-right {
         margin-right: 35px;
     }
     .col-sm-margin-35-top {
         margin-top: 35px;
     }
     .col-sm-margin-35-bottom {
         margin-bottom: 35px;
     }
     .col-sm-margin-40, .col-sm-margin-40-left {
         margin-left: 40px;
     }
     .col-sm-margin-40, .col-sm-margin-40-right {
         margin-right: 40px;
     }
     .col-sm-margin-40-top {
         margin-top: 40px;
     }
     .col-sm-margin-40-bottom {
         margin-bottom: 40px;
     }
     .col-sm-margin-45, .col-sm-margin-45-left {
         margin-left: 45px;
     }
     .col-sm-margin-45, .col-sm-margin-45-right {
         margin-right: 45px;
     }
     .col-sm-margin-45-top {
         margin-top: 45px;
     }
     .col-sm-margin-45-bottom {
         margin-bottom: 45px;
     }
     .col-sm-margin-50, .col-sm-margin-50-left {
         margin-left: 50px;
     }
     .col-sm-margin-50, .col-sm-margin-50-right {
         margin-right: 50px;
     }
     .col-sm-margin-50-top {
         margin-top: 50px;
     }
     .col-sm-margin-50-bottom {
         margin-bottom: 50px;
     }
 }
 /*change 992px to 768px to accommodate a new breakpoint at 640*/
 @media (min-width:48em) {
     .rwd:before {
         content: "md";
     }
     .container {
         max-width: 990px; /*990px;*/ /*970px;*/
     }
     .col-md-1,
     .col-md-2,
     .col-md-3,
     .col-md-4,
     .col-md-5,
     .col-md-6,
     .col-md-7,
     .col-md-8,
     .col-md-9,
     .col-md-10,
     .col-md-11 {
         float: left;
     }
     .col-md-1 {
         width: 8.333333333333332%;
     }
     .col-md-2 {
         width: 16.666666666666664%;
     }
     .col-md-3 {
         width: 25%;
     }
     .col-md-4 {
         width: 33.33333333333333%;
     }
     .col-md-5 {
         width: 41.66666666666667%;
     }
     .col-md-6 {
         width: 50%;
     }
     .col-md-7 {
         width: 58.333333333333336%;
     }
     .col-md-8 {
         width: 66.66666666666666%;
     }
     .col-md-9 {
         width: 75%;
     }
     .col-md-10 {
         width: 83.33333333333334%;
     }
     .col-md-11 {
         width: 91.66666666666666%;
     }
     .col-md-12 {
         width: 100%;
     }
     .col-md-push-0 {
         left: auto;
     }
     .col-md-push-1 {
         left: 8.333333333333332%;
     }
     .col-md-push-2 {
         left: 16.666666666666664%;
     }
     .col-md-push-3 {
         left: 25%;
     }
     .col-md-push-4 {
         left: 33.33333333333333%;
     }
     .col-md-push-5 {
         left: 41.66666666666667%;
     }
     .col-md-push-6 {
         left: 50%;
     }
     .col-md-push-7 {
         left: 58.333333333333336%;
     }
     .col-md-push-8 {
         left: 66.66666666666666%;
     }
     .col-md-push-9 {
         left: 75%;
     }
     .col-md-push-10 {
         left: 83.33333333333334%;
     }
     .col-md-push-11 {
         left: 91.66666666666666%;
     }
     .col-md-pull-0 {
         right: auto;
     }
     .col-md-pull-1 {
         right: 8.333333333333332%;
     }
     .col-md-pull-2 {
         right: 16.666666666666664%;
     }
     .col-md-pull-3 {
         right: 25%;
     }
     .col-md-pull-4 {
         right: 33.33333333333333%;
     }
     .col-md-pull-5 {
         right: 41.66666666666667%;
     }
     .col-md-pull-6 {
         right: 50%;
     }
     .col-md-pull-7 {
         right: 58.333333333333336%;
     }
     .col-md-pull-8 {
         right: 66.66666666666666%;
     }
     .col-md-pull-9 {
         right: 75%;
     }
     .col-md-pull-10 {
         right: 83.33333333333334%;
     }
     .col-md-pull-11 {
         right: 91.66666666666666%;
     }
     .col-md-offset-0 {
         margin-left: 0;
     }
     .col-md-offset-1 {
         margin-left: 8.333333333333332%;
     }
     .col-md-offset-2 {
         margin-left: 16.666666666666664%;
     }
     .col-md-offset-3 {
         margin-left: 25%;
     }
     .col-md-offset-4 {
         margin-left: 33.33333333333333%;
     }
     .col-md-offset-5 {
         margin-left: 41.66666666666667%;
     }
     .col-md-offset-6 {
         margin-left: 50%;
     }
     .col-md-offset-7 {
         margin-left: 58.333333333333336%;
     }
     .col-md-offset-8 {
         margin-left: 66.66666666666666%;
     }
     .col-md-offset-9 {
         margin-left: 75%;
     }
     .col-md-offset-10 {
         margin-left: 83.33333333333334%;
     }
     .col-md-offset-11 {
         margin-left: 91.66666666666666%;
     }
     .col-md-pad-0, .col-md-pad-0-left {
         padding-left: 0;
     }
     .col-md-pad-0, .col-md-pad-0-right {
         padding-right: 0;
     }
     .col-md-pad-0-top {
         padding-top: 0;
     }
     .col-md-pad-0-bottom {
         padding-bottom: 0;
     }
     .col-md-pad-5, .col-md-pad-5-left {
         padding-left: 5px;
     }
     .col-md-pad-5, .col-md-pad-5-right {
         padding-right: 5px;
     }
     .col-md-pad-5-top {
         padding-top: 5px;
     }
     .col-md-pad-5-bottom {
         padding-bottom: 5px;
     }
     .col-md-pad-10, .col-md-pad-10-left {
         padding-left: 10px;
     }
     .col-md-pad-10, .col-md-pad-10-right {
         padding-right: 10px;
     }
     .col-md-pad-10-top {
         padding-top: 10px;
     }
     .col-md-pad-10-bottom {
         padding-bottom: 10px;
     }
     .col-md-pad-15, .col-md-pad-15-left {
         padding-left: 15px;
     }
     .col-md-pad-15, .col-md-pad-15-right {
         padding-right: 15px;
     }
     .col-md-pad-15-top {
         padding-top: 15px;
     }
     .col-md-pad-15-bottom {
         padding-bottom: 15px;
     }
     .col-md-pad-20, .col-md-pad-20-left {
         padding-left: 20px;
     }
     .col-md-pad-20, .col-md-pad-20-right {
         padding-right: 20px;
     }
     .col-md-pad-20-top {
         padding-top: 20px;
     }
     .col-md-pad-20-bottom {
         padding-bottom: 20px;
     }
     .col-md-pad-25, .col-md-pad-25-left {
         padding-left: 25px;
     }
     .col-md-pad-25, .col-md-pad-25-right {
         padding-right: 25px;
     }
     .col-md-pad-25-top {
         padding-top: 25px;
     }
     .col-md-pad-25-bottom {
         padding-bottom: 25px;
     }
     .col-md-pad-30, .col-md-pad-30-left {
         padding-left: 30px;
     }
     .col-md-pad-30, .col-md-pad-30-right {
         padding-right: 30px;
     }
     .col-md-pad-30-top {
         padding-top: 30px;
     }
     .col-md-pad-30-bottom {
         padding-bottom: 30px;
     }
     .col-md-pad-35, .col-md-pad-35-left {
         padding-left: 35px;
     }
     .col-md-pad-35, .col-md-pad-35-right {
         padding-right: 35px;
     }
     .col-md-pad-35-top {
         padding-top: 35px;
     }
     .col-md-pad-35-bottom {
         padding-bottom: 35px;
     }
     .col-md-pad-40, .col-md-pad-40-left {
         padding-left: 40px;
     }
     .col-md-pad-40, .col-md-pad-40-right {
         padding-right: 40px;
     }
     .col-md-pad-40-top {
         padding-top: 40px;
     }
     .col-md-pad-40-bottom {
         padding-bottom: 40px;
     }
     .col-md-pad-45, .col-md-pad-45-left {
         padding-left: 45px;
     }
     .col-md-pad-45, .col-md-pad-45-right {
         padding-right: 45px;
     }
     .col-md-pad-45-top {
         padding-top: 45px;
     }
     .col-md-pad-45-bottom {
         padding-bottom: 45px;
     }
     .col-md-pad-50, .col-md-pad-50-left {
         padding-left: 50px;
     }
     .col-md-pad-50, .col-md-pad-50-right {
         padding-right: 50px;
     }
     .col-md-pad-50-top {
         padding-top: 50px;
     }
     .col-md-pad-50-bottom {
         padding-bottom: 50px;
     }
     .col-md-margin-0, .col-md-margin-0-left {
         margin-left: 0;
     }
     .col-md-margin-0, .col-md-margin-0-right {
         margin-right: 0;
     }
     .col-md-margin-0-top {
         margin-top: 0;
     }
     .col-md-margin-0-bottom {
         margin-bottom: 0;
     }
     .col-md-margin-5, .col-md-margin-5-left {
         margin-left: 5px;
     }
     .col-md-margin-5, .col-md-margin-5-right {
         margin-right: 5px;
     }
     .col-md-margin-5-top {
         margin-top: 5px;
     }
     .col-md-margin-5-bottom {
         margin-bottom: 5px;
     }
     .col-md-margin-10, .col-md-margin-10-left {
         margin-left: 10px;
     }
     .col-md-margin-10, .col-md-margin-10-right {
         margin-right: 10px;
     }
     .col-md-margin-10-top {
         margin-top: 10px;
     }
     .col-md-margin-10-bottom {
         margin-bottom: 10px;
     }
     .col-md-margin-15, .col-md-margin-15-left {
         margin-left: 15px;
     }
     .col-md-margin-15, .col-md-margin-15-right {
         margin-right: 15px;
     }
     .col-md-margin-15-top {
         margin-top: 15px;
     }
     .col-md-margin-15-bottom {
         margin-bottom: 15px;
     }
     .col-md-margin-20, .col-md-margin-20-left {
         margin-left: 20px;
     }
     .col-md-margin-20, .col-md-margin-20-right {
         margin-right: 20px;
     }
     .col-md-margin-20-top {
         margin-top: 20px;
     }
     .col-md-margin-20-bottom {
         margin-bottom: 20px;
     }
     .col-md-margin-25, .col-md-margin-25-left {
         margin-left: 25px;
     }
     .col-md-margin-25, .col-md-margin-25-right {
         margin-right: 25px;
     }
     .col-md-margin-25-top {
         margin-top: 25px;
     }
     .col-md-margin-25-bottom {
         margin-bottom: 25px;
     }
     .col-md-margin-30, .col-md-margin-30-left {
         margin-left: 30px;
     }
     .col-md-margin-30, .col-md-margin-30-right {
         margin-right: 30px;
     }
     .col-md-margin-30-top {
         margin-top: 30px;
     }
     .col-md-margin-30-bottom {
         margin-bottom: 30px;
     }
     .col-md-margin-35, .col-md-margin-35-left {
         margin-left: 35px;
     }
     .col-md-margin-35, .col-md-margin-35-right {
         margin-right: 35px;
     }
     .col-md-margin-35-top {
         margin-top: 35px;
     }
     .col-md-margin-35-bottom {
         margin-bottom: 35px;
     }
     .col-md-margin-40, .col-md-margin-40-left {
         margin-left: 40px;
     }
     .col-md-margin-40, .col-md-margin-40-right {
         margin-right: 40px;
     }
     .col-md-margin-40-top {
         margin-top: 40px;
     }
     .col-md-margin-40-bottom {
         margin-bottom: 40px;
     }
     .col-md-margin-45, .col-md-margin-45-left {
         margin-left: 45px;
     }
     .col-md-margin-45, .col-md-margin-45-right {
         margin-right: 45px;
     }
     .col-md-margin-45-top {
         margin-top: 45px;
     }
     .col-md-margin-45-bottom {
         margin-bottom: 45px;
     }
     .col-md-margin-50, .col-md-margin-50-left {
         margin-left: 50px;
     }
     .col-md-margin-50, .col-md-margin-50-right {
         margin-right: 50px;
     }
     .col-md-margin-50-top {
         margin-top: 50px;
     }
     .col-md-margin-50-bottom {
         margin-bottom: 50px;
     }
 }
 /*change 1200px to 992px to accommodate a new breakpoint at 640*/
 @media (min-width:62em) {
     .rwd:before {
         content: "lg";
     }
     .container {
         max-width: 990px; /*990px;*/ /*1170px*/
     }
     .col-lg-1,
     .col-lg-2,
     .col-lg-3,
     .col-lg-4,
     .col-lg-5,
     .col-lg-6,
     .col-lg-7,
     .col-lg-8,
     .col-lg-9,
     .col-lg-10,
     .col-lg-11 {
         float: left;
     }
     .col-lg-1 {
         width: 8.333333333333332%;
     }
     .col-lg-2 {
         width: 16.666666666666664%;
     }
     .col-lg-3 {
         width: 25%;
     }
     .col-lg-4 {
         width: 33.33333333333333%;
     }
     .col-lg-5 {
         width: 41.66666666666667%;
     }
     .col-lg-6 {
         width: 50%;
     }
     .col-lg-7 {
         width: 58.333333333333336%;
     }
     .col-lg-8 {
         width: 66.66666666666666%;
     }
     .col-lg-9 {
         width: 75%;
     }
     .col-lg-10 {
         width: 83.33333333333334%;
     }
     .col-lg-11 {
         width: 91.66666666666666%;
     }
     .col-lg-12 {
         width: 100%;
     }
     .col-lg-push-0 {
         left: auto;
     }
     .col-lg-push-1 {
         left: 8.333333333333332%;
     }
     .col-lg-push-2 {
         left: 16.666666666666664%;
     }
     .col-lg-push-3 {
         left: 25%;
     }
     .col-lg-push-4 {
         left: 33.33333333333333%;
     }
     .col-lg-push-5 {
         left: 41.66666666666667%;
     }
     .col-lg-push-6 {
         left: 50%;
     }
     .col-lg-push-7 {
         left: 58.333333333333336%;
     }
     .col-lg-push-8 {
         left: 66.66666666666666%;
     }
     .col-lg-push-9 {
         left: 75%;
     }
     .col-lg-push-10 {
         left: 83.33333333333334%;
     }
     .col-lg-push-11 {
         left: 91.66666666666666%;
     }
     .col-lg-pull-0 {
         right: auto;
     }
     .col-lg-pull-1 {
         right: 8.333333333333332%;
     }
     .col-lg-pull-2 {
         right: 16.666666666666664%;
     }
     .col-lg-pull-3 {
         right: 25%;
     }
     .col-lg-pull-4 {
         right: 33.33333333333333%;
     }
     .col-lg-pull-5 {
         right: 41.66666666666667%;
     }
     .col-lg-pull-6 {
         right: 50%;
     }
     .col-lg-pull-7 {
         right: 58.333333333333336%;
     }
     .col-lg-pull-8 {
         right: 66.66666666666666%;
     }
     .col-lg-pull-9 {
         right: 75%;
     }
     .col-lg-pull-10 {
         right: 83.33333333333334%;
     }
     .col-lg-pull-11 {
         right: 91.66666666666666%;
     }
     .col-lg-offset-0 {
         margin-left: 0;
     }
     .col-lg-offset-1 {
         margin-left: 8.333333333333332%;
     }
     .col-lg-offset-2 {
         margin-left: 16.666666666666664%;
     }
     .col-lg-offset-3 {
         margin-left: 25%;
     }
     .col-lg-offset-4 {
         margin-left: 33.33333333333333%;
     }
     .col-lg-offset-5 {
         margin-left: 41.66666666666667%;
     }
     .col-lg-offset-6 {
         margin-left: 50%;
     }
     .col-lg-offset-7 {
         margin-left: 58.333333333333336%;
     }
     .col-lg-offset-8 {
         margin-left: 66.66666666666666%;
     }
     .col-lg-offset-9 {
         margin-left: 75%;
     }
     .col-lg-offset-10 {
         margin-left: 83.33333333333334%;
     }
     .col-lg-offset-11 {
         margin-left: 91.66666666666666%;
     }
     .col-lg-pad-0, .col-lg-pad-0-left {
         padding-left: 0;
     }
     .col-lg-pad-0, .col-lg-pad-0-right {
         padding-right: 0;
     }
     .col-lg-pad-0-top {
         padding-top: 0;
     }
     .col-lg-pad-0-bottom {
         padding-bottom: 0;
     }
     .col-lg-pad-5, .col-lg-pad-5-left {
         padding-left: 5px;
     }
     .col-lg-pad-5, .col-lg-pad-5-right {
         padding-right: 5px;
     }
     .col-lg-pad-5-top {
         padding-top: 5px;
     }
     .col-lg-pad-5-bottom {
         padding-bottom: 5px;
     }
     .col-lg-pad-10, .col-lg-pad-10-left {
         padding-left: 10px;
     }
     .col-lg-pad-10, .col-lg-pad-10-right {
         padding-right: 10px;
     }
     .col-lg-pad-10-top {
         padding-top: 10px;
     }
     .col-lg-pad-10-bottom {
         padding-bottom: 10px;
     }
     .col-lg-pad-15, .col-lg-pad-15-left {
         padding-left: 15px;
     }
     .col-lg-pad-15, .col-lg-pad-15-right {
         padding-right: 15px;
     }
     .col-lg-pad-15-top {
         padding-top: 15px;
     }
     .col-lg-pad-15-bottom {
         padding-bottom: 15px;
     }
     .col-lg-pad-20, .col-lg-pad-20-left {
         padding-left: 20px;
     }
     .col-lg-pad-20, .col-lg-pad-20-right {
         padding-right: 20px;
     }
     .col-lg-pad-20-top {
         padding-top: 20px;
     }
     .col-lg-pad-20-bottom {
         padding-bottom: 20px;
     }
     .col-lg-pad-25, .col-lg-pad-25-left {
         padding-left: 25px;
     }
     .col-lg-pad-25, .col-lg-pad-25-right {
         padding-right: 25px;
     }
     .col-lg-pad-25-top {
         padding-top: 25px;
     }
     .col-lg-pad-25-bottom {
         padding-bottom: 25px;
     }
     .col-lg-pad-30, .col-lg-pad-30-left {
         padding-left: 30px;
     }
     .col-lg-pad-30, .col-lg-pad-30-right {
         padding-right: 30px;
     }
     .col-lg-pad-30-top {
         padding-top: 30px;
     }
     .col-lg-pad-30-bottom {
         padding-bottom: 30px;
     }
     .col-lg-pad-35, .col-lg-pad-35-left {
         padding-left: 35px;
     }
     .col-lg-pad-35, .col-lg-pad-35-right {
         padding-right: 35px;
     }
     .col-lg-pad-35-top {
         padding-top: 35px;
     }
     .col-lg-pad-35-bottom {
         padding-bottom: 35px;
     }
     .col-lg-pad-40, .col-lg-pad-40-left {
         padding-left: 40px;
     }
     .col-lg-pad-40, .col-lg-pad-40-right {
         padding-right: 40px;
     }
     .col-lg-pad-40-top {
         padding-top: 40px;
     }
     .col-lg-pad-40-bottom {
         padding-bottom: 40px;
     }
     .col-lg-pad-45, .col-lg-pad-45-left {
         padding-left: 45px;
     }
     .col-lg-pad-45, .col-lg-pad-45-right {
         padding-right: 45px;
     }
     .col-lg-pad-45-top {
         padding-top: 45px;
     }
     .col-lg-pad-45-bottom {
         padding-bottom: 45px;
     }
     .col-lg-pad-50, .col-lg-pad-50-left {
         padding-left: 50px;
     }
     .col-lg-pad-50, .col-lg-pad-50-right {
         padding-right: 50px;
     }
     .col-lg-pad-50-top {
         padding-top: 50px;
     }
     .col-lg-pad-50-bottom {
         padding-bottom: 50px;
     }
     .col-lg-margin-0, .col-lg-margin-0-left {
         margin-left: 0;
     }
     .col-lg-margin-0, .col-lg-margin-0-right {
         margin-right: 0;
     }
     .col-lg-margin-0-top {
         margin-top: 0;
     }
     .col-lg-margin-0-bottom {
         margin-bottom: 0;
     }
     .col-lg-margin-5, .col-lg-margin-5-left {
         margin-left: 5px;
     }
     .col-lg-margin-5, .col-lg-margin-5-right {
         margin-right: 5px;
     }
     .col-lg-margin-5-top {
         margin-top: 5px;
     }
     .col-lg-margin-5-bottom {
         margin-bottom: 5px;
     }
     .col-lg-margin-10, .col-lg-margin-10-left {
         margin-left: 10px;
     }
     .col-lg-margin-10, .col-lg-margin-10-right {
         margin-right: 10px;
     }
     .col-lg-margin-10-top {
         margin-top: 10px;
     }
     .col-lg-margin-10-bottom {
         margin-bottom: 10px;
     }
     .col-lg-margin-15, .col-lg-margin-15-left {
         margin-left: 15px;
     }
     .col-lg-margin-15, .col-lg-margin-15-right {
         margin-right: 15px;
     }
     .col-lg-margin-15-top {
         margin-top: 15px;
     }
     .col-lg-margin-15-bottom {
         margin-bottom: 15px;
     }
     .col-lg-margin-20, .col-lg-margin-20-left {
         margin-left: 20px;
     }
     .col-lg-margin-20, .col-lg-margin-20-right {
         margin-right: 20px;
     }
     .col-lg-margin-20-top {
         margin-top: 20px;
     }
     .col-lg-margin-20-bottom {
         margin-bottom: 20px;
     }
     .col-lg-margin-25, .col-lg-margin-25-left {
         margin-left: 25px;
     }
     .col-lg-margin-25, .col-lg-margin-25-right {
         margin-right: 25px;
     }
     .col-lg-margin-25-top {
         margin-top: 25px;
     }
     .col-lg-margin-25-bottom {
         margin-bottom: 25px;
     }
     .col-lg-margin-30, .col-lg-margin-30-left {
         margin-left: 30px;
     }
     .col-lg-margin-30, .col-lg-margin-30-right {
         margin-right: 30px;
     }
     .col-lg-margin-30-top {
         margin-top: 30px;
     }
     .col-lg-margin-30-bottom {
         margin-bottom: 30px;
     }
     .col-lg-margin-35, .col-lg-margin-35-left {
         margin-left: 35px;
     }
     .col-lg-margin-35, .col-lg-margin-35-right {
         margin-right: 35px;
     }
     .col-lg-margin-35-top {
         margin-top: 35px;
     }
     .col-lg-margin-35-bottom {
         margin-bottom: 35px;
     }
     .col-lg-margin-40, .col-lg-margin-40-left {
         margin-left: 40px;
     }
     .col-lg-margin-40, .col-lg-margin-40-right {
         margin-right: 40px;
     }
     .col-lg-margin-40-top {
         margin-top: 40px;
     }
     .col-lg-margin-40-bottom {
         margin-bottom: 40px;
     }
     .col-lg-margin-45, .col-lg-margin-45-left {
         margin-left: 45px;
     }
     .col-lg-margin-45, .col-lg-margin-45-right {
         margin-right: 45px;
     }
     .col-lg-margin-45-top {
         margin-top: 45px;
     }
     .col-lg-margin-45-bottom {
         margin-bottom: 45px;
     }
     .col-lg-margin-50, .col-lg-margin-50-left {
         margin-left: 50px;
     }
     .col-lg-margin-50, .col-lg-margin-50-right {
         margin-right: 50px;
     }
     .col-lg-margin-50-top {
         margin-top: 50px;
     }
     .col-lg-margin-50-bottom {
         margin-bottom: 50px;
     }
 }
 
 /* To turn off rwd, add .no-rwd to the html element */
 .no-rwd #container .container {
     max-width: 960px; /*990px;*/
     width: 960px !important; /*990px*/
 }
 
 .no-rwd .col-sm-1, .no-rwd .col-sm-2, .no-rwd .col-sm-3, .no-rwd .col-sm-4, .no-rwd .col-sm-5, .no-rwd .col-sm-6, .no-rwd .col-sm-7, .no-rwd .col-sm-8, .no-rwd .col-sm-9, .no-rwd .col-sm-10, .no-rwd .col-sm-11
 .no-rwd .col-md-1, .no-rwd .col-md-2, .no-rwd .col-md-3, .no-rwd .col-md-4, .no-rwd .col-md-5, .no-rwd .col-md-6, .no-rwd .col-md-7, .no-rwd .col-md-8, .no-rwd .col-md-9, .no-rwd .col-md-10, .no-rwd .col-md-11
 .no-rwd .col-lg-1, .no-rwd .col-lg-2, .no-rwd .col-lg-3, .no-rwd .col-lg-4, .no-rwd .col-lg-5, .no-rwd .col-lg-6, .no-rwd .col-lg-7, .no-rwd .col-lg-8, .no-rwd .col-lg-9, .no-rwd .col-lg-10, .no-rwd .col-lg-11 {
     float: left;
 }
 .no-rwd .col-sm-1 {
     width: 8.333333333333332%;
 }
 .no-rwd .col-sm-2 {
     width: 16.666666666666664%;
 }
 .no-rwd .col-sm-3 {
     width: 25%;
 }
 .no-rwd .col-sm-4 {
     width: 33.33333333333333%;
 }
 .no-rwd .col-sm-5 {
     width: 41.66666666666667%;
 }
 .no-rwd .col-sm-6 {
     width: 50%;
 }
 .no-rwd .col-sm-7 {
     width: 58.333333333333336%;
 }
 .no-rwd .col-sm-8 {
     width: 66.66666666666666%;
 }
 .no-rwd .col-sm-9 {
     width: 75%;
 }
 .no-rwd .col-sm-10 {
     width: 83.33333333333334%;
 }
 .no-rwd .col-sm-11 {
     width: 91.66666666666666%;
 }
 .no-rwd .col-sm-12 {
     width: 100%;
 }
 .no-rwd .col-sm-push-1 {
     left: 8.333333333333332%;
 }
 .no-rwd .col-sm-push-2 {
     left: 16.666666666666664%;
 }
 .no-rwd .col-sm-push-3 {
     left: 25%;
 }
 .no-rwd .col-sm-push-4 {
     left: 33.33333333333333%;
 }
 .no-rwd .col-sm-push-5 {
     left: 41.66666666666667%;
 }
 .no-rwd .col-sm-push-6 {
     left: 50%;
 }
 .no-rwd .col-sm-push-7 {
     left: 58.333333333333336%;
 }
 .no-rwd .col-sm-push-8 {
     left: 66.66666666666666%;
 }
 .no-rwd .col-sm-push-9 {
     left: 75%;
 }
 .no-rwd .col-sm-push-10 {
     left: 83.33333333333334%;
 }
 .no-rwd .col-sm-push-11 {
     left: 91.66666666666666%;
 }
 .no-rwd .col-sm-pull-1 {
     right: 8.333333333333332%;
 }
 .no-rwd .col-sm-pull-2 {
     right: 16.666666666666664%;
 }
 .no-rwd .col-sm-pull-3 {
     right: 25%;
 }
 .no-rwd .col-sm-pull-4 {
     right: 33.33333333333333%;
 }
 .no-rwd .col-sm-pull-5 {
     right: 41.66666666666667%;
 }
 .no-rwd .col-sm-pull-6 {
     right: 50%;
 }
 .no-rwd .col-sm-pull-7 {
     right: 58.333333333333336%;
 }
 .no-rwd .col-sm-pull-8 {
     right: 66.66666666666666%;
 }
 .no-rwd .col-sm-pull-9 {
     right: 75%;
 }
 .no-rwd .col-sm-pull-10 {
     right: 83.33333333333334%;
 }
 .no-rwd .col-sm-pull-11 {
     right: 91.66666666666666%;
 }
 .no-rwd .col-sm-offset-1 {
     margin-left: 8.333333333333332%;
 }
 .no-rwd .col-sm-offset-2 {
     margin-left: 16.666666666666664%;
 }
 .no-rwd .col-sm-offset-3 {
     margin-left: 25%;
 }
 .no-rwd .col-sm-offset-4 {
     margin-left: 33.33333333333333%;
 }
 .no-rwd .col-sm-offset-5 {
     margin-left: 41.66666666666667%;
 }
 .no-rwd .col-sm-offset-6 {
     margin-left: 50%;
 }
 .no-rwd .col-sm-offset-7 {
     margin-left: 58.333333333333336%;
 }
 .no-rwd .col-sm-offset-8 {
     margin-left: 66.66666666666666%;
 }
 .no-rwd .col-sm-offset-9 {
     margin-left: 75%;
 }
 .no-rwd .col-sm-offset-10 {
     margin-left: 83.33333333333334%;
 }
 .no-rwd .col-sm-offset-11 {
     margin-left: 91.66666666666666%;
 }
 .no-rwd .col-md-1 {
     width: 8.333333333333332%;
 }
 .no-rwd .col-md-2 {
     width: 16.666666666666664%;
 }
 .no-rwd .col-md-3 {
     width: 25%;
 }
 .no-rwd .col-md-4 {
     width: 33.33333333333333%;
 }
 .no-rwd .col-md-5 {
     width: 41.66666666666667%;
 }
 .no-rwd .col-md-6 {
     width: 50%;
 }
 .no-rwd .col-md-7 {
     width: 58.333333333333336%;
 }
 .no-rwd .col-md-8 {
     width: 66.66666666666666%;
 }
 .no-rwd .col-md-9 {
     width: 75%;
 }
 .no-rwd .col-md-10 {
     width: 83.33333333333334%;
 }
 .no-rwd .col-md-11 {
     width: 91.66666666666666%;
 }
 .no-rwd .col-md-12 {
     width: 100%;
 }
 .no-rwd .col-md-push-0 {
     left: auto;
 }
 .no-rwd .col-md-push-1 {
     left: 8.333333333333332%;
 }
 .no-rwd .col-md-push-2 {
     left: 16.666666666666664%;
 }
 .no-rwd .col-md-push-3 {
     left: 25%;
 }
 .no-rwd .col-md-push-4 {
     left: 33.33333333333333%;
 }
 .no-rwd .col-md-push-5 {
     left: 41.66666666666667%;
 }
 .no-rwd .col-md-push-6 {
     left: 50%;
 }
 .no-rwd .col-md-push-7 {
     left: 58.333333333333336%;
 }
 .no-rwd .col-md-push-8 {
     left: 66.66666666666666%;
 }
 .no-rwd .col-md-push-9 {
     left: 75%;
 }
 .no-rwd .col-md-push-10 {
     left: 83.33333333333334%;
 }
 .no-rwd .col-md-push-11 {
     left: 91.66666666666666%;
 }
 .no-rwd .col-md-pull-0 {
     right: auto;
 }
 .no-rwd .col-md-pull-1 {
     right: 8.333333333333332%;
 }
 .no-rwd .col-md-pull-2 {
     right: 16.666666666666664%;
 }
 .no-rwd .col-md-pull-3 {
     right: 25%;
 }
 .no-rwd .col-md-pull-4 {
     right: 33.33333333333333%;
 }
 .no-rwd .col-md-pull-5 {
     right: 41.66666666666667%;
 }
 .no-rwd .col-md-pull-6 {
     right: 50%;
 }
 .no-rwd .col-md-pull-7 {
     right: 58.333333333333336%;
 }
 .no-rwd .col-md-pull-8 {
     right: 66.66666666666666%;
 }
 .no-rwd .col-md-pull-9 {
     right: 75%;
 }
 .no-rwd .col-md-pull-10 {
     right: 83.33333333333334%;
 }
 .no-rwd .col-md-pull-11 {
     right: 91.66666666666666%;
 }
 .no-rwd .col-md-offset-0 {
     margin-left: 0;
 }
 .no-rwd .col-md-offset-1 {
     margin-left: 8.333333333333332%;
 }
 .no-rwd .col-md-offset-2 {
     margin-left: 16.666666666666664%;
 }
 .no-rwd .col-md-offset-3 {
     margin-left: 25%;
 }
 .no-rwd .col-md-offset-4 {
     margin-left: 33.33333333333333%;
 }
 .no-rwd .col-md-offset-5 {
     margin-left: 41.66666666666667%;
 }
 .no-rwd .col-md-offset-6 {
     margin-left: 50%;
 }
 .no-rwd .col-md-offset-7 {
     margin-left: 58.333333333333336%;
 }
 .no-rwd .col-md-offset-8 {
     margin-left: 66.66666666666666%;
 }
 .no-rwd .col-md-offset-9 {
     margin-left: 75%;
 }
 .no-rwd .col-md-offset-10 {
     margin-left: 83.33333333333334%;
 }
 .no-rwd .col-md-offset-11 {
     margin-left: 91.66666666666666%;
 }
 .no-rwd .col-lg-1 {
     width: 8.333333333333332%;
 }
 .no-rwd .col-lg-2 {
     width: 16.666666666666664%;
 }
 .no-rwd .col-lg-3 {
     width: 25%;
 }
 .no-rwd .col-lg-4 {
     width: 33.33333333333333%;
 }
 .no-rwd .col-lg-5 {
     width: 41.66666666666667%;
 }
 .no-rwd .col-lg-6 {
     width: 50%;
 }
 .no-rwd .col-lg-7 {
     width: 58.333333333333336%;
 }
 .no-rwd .col-lg-8 {
     width: 66.66666666666666%;
 }
 .no-rwd .col-lg-9 {
     width: 75%;
 }
 .no-rwd .col-lg-10 {
     width: 83.33333333333334%;
 }
 .no-rwd .col-lg-11 {
     width: 91.66666666666666%;
 }
 .no-rwd .col-lg-12 {
     width: 100%;
 }
 .no-rwd .col-lg-push-0 {
     left: auto;
 }
 .no-rwd .col-lg-push-1 {
     left: 8.333333333333332%;
 }
 .no-rwd .col-lg-push-2 {
     left: 16.666666666666664%;
 }
 .no-rwd .col-lg-push-3 {
     left: 25%;
 }
 .no-rwd .col-lg-push-4 {
     left: 33.33333333333333%;
 }
 .no-rwd .col-lg-push-5 {
     left: 41.66666666666667%;
 }
 .no-rwd .col-lg-push-6 {
     left: 50%;
 }
 .no-rwd .col-lg-push-7 {
     left: 58.333333333333336%;
 }
 .no-rwd .col-lg-push-8 {
     left: 66.66666666666666%;
 }
 .no-rwd .col-lg-push-9 {
     left: 75%;
 }
 .no-rwd .col-lg-push-10 {
     left: 83.33333333333334%;
 }
 .no-rwd .col-lg-push-11 {
     left: 91.66666666666666%;
 }
 .no-rwd .col-lg-pull-0 {
     right: auto;
 }
 .no-rwd .col-lg-pull-1 {
     right: 8.333333333333332%;
 }
 .no-rwd .col-lg-pull-2 {
     right: 16.666666666666664%;
 }
 .no-rwd .col-lg-pull-3 {
     right: 25%;
 }
 .no-rwd .col-lg-pull-4 {
     right: 33.33333333333333%;
 }
 .no-rwd .col-lg-pull-5 {
     right: 41.66666666666667%;
 }
 .no-rwd .col-lg-pull-6 {
     right: 50%;
 }
 .no-rwd .col-lg-pull-7 {
     right: 58.333333333333336%;
 }
 .no-rwd .col-lg-pull-8 {
     right: 66.66666666666666%;
 }
 .no-rwd .col-lg-pull-9 {
     right: 75%;
 }
 .no-rwd .col-lg-pull-10 {
     right: 83.33333333333334%;
 }
 .no-rwd .col-lg-pull-11 {
     right: 91.66666666666666%;
 }
 .no-rwd .col-lg-offset-0 {
     margin-left: 0;
 }
 .no-rwd .col-lg-offset-1 {
     margin-left: 8.333333333333332%;
 }
 .no-rwd .col-lg-offset-2 {
     margin-left: 16.666666666666664%;
 }
 .no-rwd .col-lg-offset-3 {
     margin-left: 25%;
 }
 .no-rwd .col-lg-offset-4 {
     margin-left: 33.33333333333333%;
 }
 .no-rwd .col-lg-offset-5 {
     margin-left: 41.66666666666667%;
 }
 .no-rwd .col-lg-offset-6 {
     margin-left: 50%;
 }
 .no-rwd .col-lg-offset-7 {
     margin-left: 58.333333333333336%;
 }
 .no-rwd .col-lg-offset-8 {
     margin-left: 66.66666666666666%;
 }
 .no-rwd .col-lg-offset-9 {
     margin-left: 75%;
 }
 .no-rwd .col-lg-offset-10 {
     margin-left: 83.33333333333334%;
 }
 .no-rwd .col-lg-offset-11 {
     margin-left: 91.66666666666666%;
 }
 @media print {
     .rwd:before {
         content: "sm";
     }
     .container {
         max-width: 768px; /*990px;*/ /*750px;*/
     }
     .col-sm-1,
     .col-sm-2,
     .col-sm-3,
     .col-sm-4,
     .col-sm-5,
     .col-sm-6,
     .col-sm-7,
     .col-sm-8,
     .col-sm-9,
     .col-sm-10,
     .col-sm-11 {
         float: left;
     }
     .col-sm-1 {
         width: 8.333333333333332%;
     }
     .col-sm-2 {
         width: 16.666666666666664%;
     }
     .col-sm-3 {
         width: 25%;
     }
     .col-sm-4 {
         width: 33.33333333333333%;
     }
     .col-sm-5 {
         width: 41.66666666666667%;
     }
     .col-sm-6 {
         width: 50%;
     }
     .col-sm-7 {
         width: 58.333333333333336%;
     }
     .col-sm-8 {
         width: 66.66666666666666%;
     }
     .col-sm-9 {
         width: 75%;
     }
     .col-sm-10 {
         width: 83.33333333333334%;
     }
     .col-sm-11 {
         width: 91.66666666666666%;
     }
     .col-sm-12 {
         width: 100%;
     }
     .col-sm-push-0 {
         left: auto;
     }
     .col-sm-push-1 {
         left: 8.333333333333332%;
     }
     .col-sm-push-2 {
         left: 16.666666666666664%;
     }
     .col-sm-push-3 {
         left: 25%;
     }
     .col-sm-push-4 {
         left: 33.33333333333333%;
     }
     .col-sm-push-5 {
         left: 41.66666666666667%;
     }
     .col-sm-push-6 {
         left: 50%;
     }
     .col-sm-push-7 {
         left: 58.333333333333336%;
     }
     .col-sm-push-8 {
         left: 66.66666666666666%;
     }
     .col-sm-push-9 {
         left: 75%;
     }
     .col-sm-push-10 {
         left: 83.33333333333334%;
     }
     .col-sm-push-11 {
         left: 91.66666666666666%;
     }
     .col-sm-pull-0 {
         right: auto;
     }
     .col-sm-pull-1 {
         right: 8.333333333333332%;
     }
     .col-sm-pull-2 {
         right: 16.666666666666664%;
     }
     .col-sm-pull-3 {
         right: 25%;
     }
     .col-sm-pull-4 {
         right: 33.33333333333333%;
     }
     .col-sm-pull-5 {
         right: 41.66666666666667%;
     }
     .col-sm-pull-6 {
         right: 50%;
     }
     .col-sm-pull-7 {
         right: 58.333333333333336%;
     }
     .col-sm-pull-8 {
         right: 66.66666666666666%;
     }
     .col-sm-pull-9 {
         right: 75%;
     }
     .col-sm-pull-10 {
         right: 83.33333333333334%;
     }
     .col-sm-pull-11 {
         right: 91.66666666666666%;
     }
     .col-sm-offset-0 {
         margin-left: 0;
     }
     .col-sm-offset-1 {
         margin-left: 8.333333333333332%;
     }
     .col-sm-offset-2 {
         margin-left: 16.666666666666664%;
     }
     .col-sm-offset-3 {
         margin-left: 25%;
     }
     .col-sm-offset-4 {
         margin-left: 33.33333333333333%;
     }
     .col-sm-offset-5 {
         margin-left: 41.66666666666667%;
     }
     .col-sm-offset-6 {
         margin-left: 50%;
     }
     .col-sm-offset-7 {
         margin-left: 58.333333333333336%;
     }
     .col-sm-offset-8 {
         margin-left: 66.66666666666666%;
     }
     .col-sm-offset-9 {
         margin-left: 75%;
     }
     .col-sm-offset-10 {
         margin-left: 83.33333333333334%;
     }
     .col-sm-offset-11 {
         margin-left: 91.66666666666666%;
     }
     .col-sm-pad-0, .col-sm-pad-0-left {
         padding-left: 0;
     }
     .col-sm-pad-0, .col-sm-pad-0-right {
         padding-right: 0;
     }
     .col-sm-pad-0-top {
         padding-top: 0;
     }
     .col-sm-pad-0-bottom {
         padding-bottom: 0;
     }
     .col-sm-pad-5, .col-sm-pad-5-left {
         padding-left: 5px;
     }
     .col-sm-pad-5, .col-sm-pad-5-right {
         padding-right: 5px;
     }
     .col-sm-pad-5-top {
         padding-top: 5px;
     }
     .col-sm-pad-5-bottom {
         padding-bottom: 5px;
     }
     .col-sm-pad-10, .col-sm-pad-10-left {
         padding-left: 10px;
     }
     .col-sm-pad-10, .col-sm-pad-10-right {
         padding-right: 10px;
     }
     .col-sm-pad-10-top {
         padding-top: 10px;
     }
     .col-sm-pad-10-bottom {
         padding-bottom: 10px;
     }
     .col-sm-pad-15, .col-sm-pad-15-left {
         padding-left: 15px;
     }
     .col-sm-pad-15, .col-sm-pad-15-right {
         padding-right: 15px;
     }
     .col-sm-pad-15-top {
         padding-top: 15px;
     }
     .col-sm-pad-15-bottom {
         padding-bottom: 15px;
     }
     .col-sm-pad-20, .col-sm-pad-20-left {
         padding-left: 20px;
     }
     .col-sm-pad-20, .col-sm-pad-20-right {
         padding-right: 20px;
     }
     .col-sm-pad-20-top {
         padding-top: 20px;
     }
     .col-sm-pad-20-bottom {
         padding-bottom: 20px;
     }
     .col-sm-pad-25, .col-sm-pad-25-left {
         padding-left: 25px;
     }
     .col-sm-pad-25, .col-sm-pad-25-right {
         padding-right: 25px;
     }
     .col-sm-pad-25-top {
         padding-top: 25px;
     }
     .col-sm-pad-25-bottom {
         padding-bottom: 25px;
     }
     .col-sm-pad-30, .col-sm-pad-30-left {
         padding-left: 30px;
     }
     .col-sm-pad-30, .col-sm-pad-30-right {
         padding-right: 30px;
     }
     .col-sm-pad-30-top {
         padding-top: 30px;
     }
     .col-sm-pad-30-bottom {
         padding-bottom: 30px;
     }
     .col-sm-pad-35, .col-sm-pad-35-left {
         padding-left: 35px;
     }
     .col-sm-pad-35, .col-sm-pad-35-right {
         padding-right: 35px;
     }
     .col-sm-pad-35-top {
         padding-top: 35px;
     }
     .col-sm-pad-35-bottom {
         padding-bottom: 35px;
     }
     .col-sm-pad-40, .col-sm-pad-40-left {
         padding-left: 40px;
     }
     .col-sm-pad-40, .col-sm-pad-40-right {
         padding-right: 40px;
     }
     .col-sm-pad-40-top {
         padding-top: 40px;
     }
     .col-sm-pad-40-bottom {
         padding-bottom: 40px;
     }
     .col-sm-pad-45, .col-sm-pad-45-left {
         padding-left: 45px;
     }
     .col-sm-pad-45, .col-sm-pad-45-right {
         padding-right: 45px;
     }
     .col-sm-pad-45-top {
         padding-top: 45px;
     }
     .col-sm-pad-45-bottom {
         padding-bottom: 45px;
     }
     .col-sm-pad-50, .col-sm-pad-50-left {
         padding-left: 50px;
     }
     .col-sm-pad-50, .col-sm-pad-50-right {
         padding-right: 50px;
     }
     .col-sm-pad-50-top {
         padding-top: 50px;
     }
     .col-sm-pad-50-bottom {
         padding-bottom: 50px;
     }
     .col-sm-margin-0, .col-sm-margin-0-left {
         margin-left: 0;
     }
     .col-sm-margin-0, .col-sm-margin-0-right {
         margin-right: 0;
     }
     .col-sm-margin-0-top {
         margin-top: 0;
     }
     .col-sm-margin-0-bottom {
         margin-bottom: 0;
     }
     .col-sm-margin-5, .col-sm-margin-5-left {
         margin-left: 5px;
     }
     .col-sm-margin-5, .col-sm-margin-5-right {
         margin-right: 5px;
     }
     .col-sm-margin-5-top {
         margin-top: 5px;
     }
     .col-sm-margin-5-bottom {
         margin-bottom: 5px;
     }
     .col-sm-margin-10, .col-sm-margin-10-left {
         margin-left: 10px;
     }
     .col-sm-margin-10, .col-sm-margin-10-right {
         margin-right: 10px;
     }
     .col-sm-margin-10-top {
         margin-top: 10px;
     }
     .col-sm-margin-10-bottom {
         margin-bottom: 10px;
     }
     .col-sm-margin-15, .col-sm-margin-15-left {
         margin-left: 15px;
     }
     .col-sm-margin-15, .col-sm-margin-15-right {
         margin-right: 15px;
     }
     .col-sm-margin-15-top {
         margin-top: 15px;
     }
     .col-sm-margin-15-bottom {
         margin-bottom: 15px;
     }
     .col-sm-margin-20, .col-sm-margin-20-left {
         margin-left: 20px;
     }
     .col-sm-margin-20, .col-sm-margin-20-right {
         margin-right: 20px;
     }
     .col-sm-margin-20-top {
         margin-top: 20px;
     }
     .col-sm-margin-20-bottom {
         margin-bottom: 20px;
     }
     .col-sm-margin-25, .col-sm-margin-25-left {
         margin-left: 25px;
     }
     .col-sm-margin-25, .col-sm-margin-25-right {
         margin-right: 25px;
     }
     .col-sm-margin-25-top {
         margin-top: 25px;
     }
     .col-sm-margin-25-bottom {
         margin-bottom: 25px;
     }
     .col-sm-margin-30, .col-sm-margin-30-left {
         margin-left: 30px;
     }
     .col-sm-margin-30, .col-sm-margin-30-right {
         margin-right: 30px;
     }
     .col-sm-margin-30-top {
         margin-top: 30px;
     }
     .col-sm-margin-30-bottom {
         margin-bottom: 30px;
     }
     .col-sm-margin-35, .col-sm-margin-35-left {
         margin-left: 35px;
     }
     .col-sm-margin-35, .col-sm-margin-35-right {
         margin-right: 35px;
     }
     .col-sm-margin-35-top {
         margin-top: 35px;
     }
     .col-sm-margin-35-bottom {
         margin-bottom: 35px;
     }
     .col-sm-margin-40, .col-sm-margin-40-left {
         margin-left: 40px;
     }
     .col-sm-margin-40, .col-sm-margin-40-right {
         margin-right: 40px;
     }
     .col-sm-margin-40-top {
         margin-top: 40px;
     }
     .col-sm-margin-40-bottom {
         margin-bottom: 40px;
     }
     .col-sm-margin-45, .col-sm-margin-45-left {
         margin-left: 45px;
     }
     .col-sm-margin-45, .col-sm-margin-45-right {
         margin-right: 45px;
     }
     .col-sm-margin-45-top {
         margin-top: 45px;
     }
     .col-sm-margin-45-bottom {
         margin-bottom: 45px;
     }
     .col-sm-margin-50, .col-sm-margin-50-left {
         margin-left: 50px;
     }
     .col-sm-margin-50, .col-sm-margin-50-right {
         margin-right: 50px;
     }
     .col-sm-margin-50-top {
         margin-top: 50px;
     }
     .col-sm-margin-50-bottom {
         margin-bottom: 50px;
     }
 
     .rwd:before {
         content: "md";
     }
     .container {
         max-width: 990px; /*990px;*/ /*970px;*/
     }
     .col-md-1,
     .col-md-2,
     .col-md-3,
     .col-md-4,
     .col-md-5,
     .col-md-6,
     .col-md-7,
     .col-md-8,
     .col-md-9,
     .col-md-10,
     .col-md-11 {
         float: left;
     }
     .col-md-1 {
         width: 8.333333333333332%;
     }
     .col-md-2 {
         width: 16.666666666666664%;
     }
     .col-md-3 {
         width: 25%;
     }
     .col-md-4 {
         width: 33.33333333333333%;
     }
     .col-md-5 {
         width: 41.66666666666667%;
     }
     .col-md-6 {
         width: 50%;
     }
     .col-md-7 {
         width: 58.333333333333336%;
     }
     .col-md-8 {
         width: 66.66666666666666%;
     }
     .col-md-9 {
         width: 75%;
     }
     .col-md-10 {
         width: 83.33333333333334%;
     }
     .col-md-11 {
         width: 91.66666666666666%;
     }
     .col-md-12 {
         width: 100%;
     }
     .col-md-push-0 {
         left: auto;
     }
     .col-md-push-1 {
         left: 8.333333333333332%;
     }
     .col-md-push-2 {
         left: 16.666666666666664%;
     }
     .col-md-push-3 {
         left: 25%;
     }
     .col-md-push-4 {
         left: 33.33333333333333%;
     }
     .col-md-push-5 {
         left: 41.66666666666667%;
     }
     .col-md-push-6 {
         left: 50%;
     }
     .col-md-push-7 {
         left: 58.333333333333336%;
     }
     .col-md-push-8 {
         left: 66.66666666666666%;
     }
     .col-md-push-9 {
         left: 75%;
     }
     .col-md-push-10 {
         left: 83.33333333333334%;
     }
     .col-md-push-11 {
         left: 91.66666666666666%;
     }
     .col-md-pull-0 {
         right: auto;
     }
     .col-md-pull-1 {
         right: 8.333333333333332%;
     }
     .col-md-pull-2 {
         right: 16.666666666666664%;
     }
     .col-md-pull-3 {
         right: 25%;
     }
     .col-md-pull-4 {
         right: 33.33333333333333%;
     }
     .col-md-pull-5 {
         right: 41.66666666666667%;
     }
     .col-md-pull-6 {
         right: 50%;
     }
     .col-md-pull-7 {
         right: 58.333333333333336%;
     }
     .col-md-pull-8 {
         right: 66.66666666666666%;
     }
     .col-md-pull-9 {
         right: 75%;
     }
     .col-md-pull-10 {
         right: 83.33333333333334%;
     }
     .col-md-pull-11 {
         right: 91.66666666666666%;
     }
     .col-md-offset-0 {
         margin-left: 0;
     }
     .col-md-offset-1 {
         margin-left: 8.333333333333332%;
     }
     .col-md-offset-2 {
         margin-left: 16.666666666666664%;
     }
     .col-md-offset-3 {
         margin-left: 25%;
     }
     .col-md-offset-4 {
         margin-left: 33.33333333333333%;
     }
     .col-md-offset-5 {
         margin-left: 41.66666666666667%;
     }
     .col-md-offset-6 {
         margin-left: 50%;
     }
     .col-md-offset-7 {
         margin-left: 58.333333333333336%;
     }
     .col-md-offset-8 {
         margin-left: 66.66666666666666%;
     }
     .col-md-offset-9 {
         margin-left: 75%;
     }
     .col-md-offset-10 {
         margin-left: 83.33333333333334%;
     }
     .col-md-offset-11 {
         margin-left: 91.66666666666666%;
     }
     .col-md-pad-0, .col-md-pad-0-left {
         padding-left: 0;
     }
     .col-md-pad-0, .col-md-pad-0-right {
         padding-right: 0;
     }
     .col-md-pad-0-top {
         padding-top: 0;
     }
     .col-md-pad-0-bottom {
         padding-bottom: 0;
     }
     .col-md-pad-5, .col-md-pad-5-left {
         padding-left: 5px;
     }
     .col-md-pad-5, .col-md-pad-5-right {
         padding-right: 5px;
     }
     .col-md-pad-5-top {
         padding-top: 5px;
     }
     .col-md-pad-5-bottom {
         padding-bottom: 5px;
     }
     .col-md-pad-10, .col-md-pad-10-left {
         padding-left: 10px;
     }
     .col-md-pad-10, .col-md-pad-10-right {
         padding-right: 10px;
     }
     .col-md-pad-10-top {
         padding-top: 10px;
     }
     .col-md-pad-10-bottom {
         padding-bottom: 10px;
     }
     .col-md-pad-15, .col-md-pad-15-left {
         padding-left: 15px;
     }
     .col-md-pad-15, .col-md-pad-15-right {
         padding-right: 15px;
     }
     .col-md-pad-15-top {
         padding-top: 15px;
     }
     .col-md-pad-15-bottom {
         padding-bottom: 15px;
     }
     .col-md-pad-20, .col-md-pad-20-left {
         padding-left: 20px;
     }
     .col-md-pad-20, .col-md-pad-20-right {
         padding-right: 20px;
     }
     .col-md-pad-20-top {
         padding-top: 20px;
     }
     .col-md-pad-20-bottom {
         padding-bottom: 20px;
     }
     .col-md-pad-25, .col-md-pad-25-left {
         padding-left: 25px;
     }
     .col-md-pad-25, .col-md-pad-25-right {
         padding-right: 25px;
     }
     .col-md-pad-25-top {
         padding-top: 25px;
     }
     .col-md-pad-25-bottom {
         padding-bottom: 25px;
     }
     .col-md-pad-30, .col-md-pad-30-left {
         padding-left: 30px;
     }
     .col-md-pad-30, .col-md-pad-30-right {
         padding-right: 30px;
     }
     .col-md-pad-30-top {
         padding-top: 30px;
     }
     .col-md-pad-30-bottom {
         padding-bottom: 30px;
     }
     .col-md-pad-35, .col-md-pad-35-left {
         padding-left: 35px;
     }
     .col-md-pad-35, .col-md-pad-35-right {
         padding-right: 35px;
     }
     .col-md-pad-35-top {
         padding-top: 35px;
     }
     .col-md-pad-35-bottom {
         padding-bottom: 35px;
     }
     .col-md-pad-40, .col-md-pad-40-left {
         padding-left: 40px;
     }
     .col-md-pad-40, .col-md-pad-40-right {
         padding-right: 40px;
     }
     .col-md-pad-40-top {
         padding-top: 40px;
     }
     .col-md-pad-40-bottom {
         padding-bottom: 40px;
     }
     .col-md-pad-45, .col-md-pad-45-left {
         padding-left: 45px;
     }
     .col-md-pad-45, .col-md-pad-45-right {
         padding-right: 45px;
     }
     .col-md-pad-45-top {
         padding-top: 45px;
     }
     .col-md-pad-45-bottom {
         padding-bottom: 45px;
     }
     .col-md-pad-50, .col-md-pad-50-left {
         padding-left: 50px;
     }
     .col-md-pad-50, .col-md-pad-50-right {
         padding-right: 50px;
     }
     .col-md-pad-50-top {
         padding-top: 50px;
     }
     .col-md-pad-50-bottom {
         padding-bottom: 50px;
     }
     .col-md-margin-0, .col-md-margin-0-left {
         margin-left: 0;
     }
     .col-md-margin-0, .col-md-margin-0-right {
         margin-right: 0;
     }
     .col-md-margin-0-top {
         margin-top: 0;
     }
     .col-md-margin-0-bottom {
         margin-bottom: 0;
     }
     .col-md-margin-5, .col-md-margin-5-left {
         margin-left: 5px;
     }
     .col-md-margin-5, .col-md-margin-5-right {
         margin-right: 5px;
     }
     .col-md-margin-5-top {
         margin-top: 5px;
     }
     .col-md-margin-5-bottom {
         margin-bottom: 5px;
     }
     .col-md-margin-10, .col-md-margin-10-left {
         margin-left: 10px;
     }
     .col-md-margin-10, .col-md-margin-10-right {
         margin-right: 10px;
     }
     .col-md-margin-10-top {
         margin-top: 10px;
     }
     .col-md-margin-10-bottom {
         margin-bottom: 10px;
     }
     .col-md-margin-15, .col-md-margin-15-left {
         margin-left: 15px;
     }
     .col-md-margin-15, .col-md-margin-15-right {
         margin-right: 15px;
     }
     .col-md-margin-15-top {
         margin-top: 15px;
     }
     .col-md-margin-15-bottom {
         margin-bottom: 15px;
     }
     .col-md-margin-20, .col-md-margin-20-left {
         margin-left: 20px;
     }
     .col-md-margin-20, .col-md-margin-20-right {
         margin-right: 20px;
     }
     .col-md-margin-20-top {
         margin-top: 20px;
     }
     .col-md-margin-20-bottom {
         margin-bottom: 20px;
     }
     .col-md-margin-25, .col-md-margin-25-left {
         margin-left: 25px;
     }
     .col-md-margin-25, .col-md-margin-25-right {
         margin-right: 25px;
     }
     .col-md-margin-25-top {
         margin-top: 25px;
     }
     .col-md-margin-25-bottom {
         margin-bottom: 25px;
     }
     .col-md-margin-30, .col-md-margin-30-left {
         margin-left: 30px;
     }
     .col-md-margin-30, .col-md-margin-30-right {
         margin-right: 30px;
     }
     .col-md-margin-30-top {
         margin-top: 30px;
     }
     .col-md-margin-30-bottom {
         margin-bottom: 30px;
     }
     .col-md-margin-35, .col-md-margin-35-left {
         margin-left: 35px;
     }
     .col-md-margin-35, .col-md-margin-35-right {
         margin-right: 35px;
     }
     .col-md-margin-35-top {
         margin-top: 35px;
     }
     .col-md-margin-35-bottom {
         margin-bottom: 35px;
     }
     .col-md-margin-40, .col-md-margin-40-left {
         margin-left: 40px;
     }
     .col-md-margin-40, .col-md-margin-40-right {
         margin-right: 40px;
     }
     .col-md-margin-40-top {
         margin-top: 40px;
     }
     .col-md-margin-40-bottom {
         margin-bottom: 40px;
     }
     .col-md-margin-45, .col-md-margin-45-left {
         margin-left: 45px;
     }
     .col-md-margin-45, .col-md-margin-45-right {
         margin-right: 45px;
     }
     .col-md-margin-45-top {
         margin-top: 45px;
     }
     .col-md-margin-45-bottom {
         margin-bottom: 45px;
     }
     .col-md-margin-50, .col-md-margin-50-left {
         margin-left: 50px;
     }
     .col-md-margin-50, .col-md-margin-50-right {
         margin-right: 50px;
     }
     .col-md-margin-50-top {
         margin-top: 50px;
     }
     .col-md-margin-50-bottom {
         margin-bottom: 50px;
     }
 
     .rwd:before {
         content: "lg";
     }
     .container {
         max-width: 990px; /*990px;*/ /*1170px*/
     }
     .col-lg-1,
     .col-lg-2,
     .col-lg-3,
     .col-lg-4,
     .col-lg-5,
     .col-lg-6,
     .col-lg-7,
     .col-lg-8,
     .col-lg-9,
     .col-lg-10,
     .col-lg-11 {
         float: left;
     }
     .col-lg-1 {
         width: 8.333333333333332%;
     }
     .col-lg-2 {
         width: 16.666666666666664%;
     }
     .col-lg-3 {
         width: 25%;
     }
     .col-lg-4 {
         width: 33.33333333333333%;
     }
     .col-lg-5 {
         width: 41.66666666666667%;
     }
     .col-lg-6 {
         width: 50%;
     }
     .col-lg-7 {
         width: 58.333333333333336%;
     }
     .col-lg-8 {
         width: 66.66666666666666%;
     }
     .col-lg-9 {
         width: 75%;
     }
     .col-lg-10 {
         width: 83.33333333333334%;
     }
     .col-lg-11 {
         width: 91.66666666666666%;
     }
     .col-lg-12 {
         width: 100%;
     }
     .col-lg-push-0 {
         left: auto;
     }
     .col-lg-push-1 {
         left: 8.333333333333332%;
     }
     .col-lg-push-2 {
         left: 16.666666666666664%;
     }
     .col-lg-push-3 {
         left: 25%;
     }
     .col-lg-push-4 {
         left: 33.33333333333333%;
     }
     .col-lg-push-5 {
         left: 41.66666666666667%;
     }
     .col-lg-push-6 {
         left: 50%;
     }
     .col-lg-push-7 {
         left: 58.333333333333336%;
     }
     .col-lg-push-8 {
         left: 66.66666666666666%;
     }
     .col-lg-push-9 {
         left: 75%;
     }
     .col-lg-push-10 {
         left: 83.33333333333334%;
     }
     .col-lg-push-11 {
         left: 91.66666666666666%;
     }
     .col-lg-pull-0 {
         right: auto;
     }
     .col-lg-pull-1 {
         right: 8.333333333333332%;
     }
     .col-lg-pull-2 {
         right: 16.666666666666664%;
     }
     .col-lg-pull-3 {
         right: 25%;
     }
     .col-lg-pull-4 {
         right: 33.33333333333333%;
     }
     .col-lg-pull-5 {
         right: 41.66666666666667%;
     }
     .col-lg-pull-6 {
         right: 50%;
     }
     .col-lg-pull-7 {
         right: 58.333333333333336%;
     }
     .col-lg-pull-8 {
         right: 66.66666666666666%;
     }
     .col-lg-pull-9 {
         right: 75%;
     }
     .col-lg-pull-10 {
         right: 83.33333333333334%;
     }
     .col-lg-pull-11 {
         right: 91.66666666666666%;
     }
     .col-lg-offset-0 {
         margin-left: 0;
     }
     .col-lg-offset-1 {
         margin-left: 8.333333333333332%;
     }
     .col-lg-offset-2 {
         margin-left: 16.666666666666664%;
     }
     .col-lg-offset-3 {
         margin-left: 25%;
     }
     .col-lg-offset-4 {
         margin-left: 33.33333333333333%;
     }
     .col-lg-offset-5 {
         margin-left: 41.66666666666667%;
     }
     .col-lg-offset-6 {
         margin-left: 50%;
     }
     .col-lg-offset-7 {
         margin-left: 58.333333333333336%;
     }
     .col-lg-offset-8 {
         margin-left: 66.66666666666666%;
     }
     .col-lg-offset-9 {
         margin-left: 75%;
     }
     .col-lg-offset-10 {
         margin-left: 83.33333333333334%;
     }
     .col-lg-offset-11 {
         margin-left: 91.66666666666666%;
     }
     .col-lg-pad-0, .col-lg-pad-0-left {
         padding-left: 0;
     }
     .col-lg-pad-0, .col-lg-pad-0-right {
         padding-right: 0;
     }
     .col-lg-pad-0-top {
         padding-top: 0;
     }
     .col-lg-pad-0-bottom {
         padding-bottom: 0;
     }
     .col-lg-pad-5, .col-lg-pad-5-left {
         padding-left: 5px;
     }
     .col-lg-pad-5, .col-lg-pad-5-right {
         padding-right: 5px;
     }
     .col-lg-pad-5-top {
         padding-top: 5px;
     }
     .col-lg-pad-5-bottom {
         padding-bottom: 5px;
     }
     .col-lg-pad-10, .col-lg-pad-10-left {
         padding-left: 10px;
     }
     .col-lg-pad-10, .col-lg-pad-10-right {
         padding-right: 10px;
     }
     .col-lg-pad-10-top {
         padding-top: 10px;
     }
     .col-lg-pad-10-bottom {
         padding-bottom: 10px;
     }
     .col-lg-pad-15, .col-lg-pad-15-left {
         padding-left: 15px;
     }
     .col-lg-pad-15, .col-lg-pad-15-right {
         padding-right: 15px;
     }
     .col-lg-pad-15-top {
         padding-top: 15px;
     }
     .col-lg-pad-15-bottom {
         padding-bottom: 15px;
     }
     .col-lg-pad-20, .col-lg-pad-20-left {
         padding-left: 20px;
     }
     .col-lg-pad-20, .col-lg-pad-20-right {
         padding-right: 20px;
     }
     .col-lg-pad-20-top {
         padding-top: 20px;
     }
     .col-lg-pad-20-bottom {
         padding-bottom: 20px;
     }
     .col-lg-pad-25, .col-lg-pad-25-left {
         padding-left: 25px;
     }
     .col-lg-pad-25, .col-lg-pad-25-right {
         padding-right: 25px;
     }
     .col-lg-pad-25-top {
         padding-top: 25px;
     }
     .col-lg-pad-25-bottom {
         padding-bottom: 25px;
     }
     .col-lg-pad-30, .col-lg-pad-30-left {
         padding-left: 30px;
     }
     .col-lg-pad-30, .col-lg-pad-30-right {
         padding-right: 30px;
     }
     .col-lg-pad-30-top {
         padding-top: 30px;
     }
     .col-lg-pad-30-bottom {
         padding-bottom: 30px;
     }
     .col-lg-pad-35, .col-lg-pad-35-left {
         padding-left: 35px;
     }
     .col-lg-pad-35, .col-lg-pad-35-right {
         padding-right: 35px;
     }
     .col-lg-pad-35-top {
         padding-top: 35px;
     }
     .col-lg-pad-35-bottom {
         padding-bottom: 35px;
     }
     .col-lg-pad-40, .col-lg-pad-40-left {
         padding-left: 40px;
     }
     .col-lg-pad-40, .col-lg-pad-40-right {
         padding-right: 40px;
     }
     .col-lg-pad-40-top {
         padding-top: 40px;
     }
     .col-lg-pad-40-bottom {
         padding-bottom: 40px;
     }
     .col-lg-pad-45, .col-lg-pad-45-left {
         padding-left: 45px;
     }
     .col-lg-pad-45, .col-lg-pad-45-right {
         padding-right: 45px;
     }
     .col-lg-pad-45-top {
         padding-top: 45px;
     }
     .col-lg-pad-45-bottom {
         padding-bottom: 45px;
     }
     .col-lg-pad-50, .col-lg-pad-50-left {
         padding-left: 50px;
     }
     .col-lg-pad-50, .col-lg-pad-50-right {
         padding-right: 50px;
     }
     .col-lg-pad-50-top {
         padding-top: 50px;
     }
     .col-lg-pad-50-bottom {
         padding-bottom: 50px;
     }
     .col-lg-margin-0, .col-lg-margin-0-left {
         margin-left: 0;
     }
     .col-lg-margin-0, .col-lg-margin-0-right {
         margin-right: 0;
     }
     .col-lg-margin-0-top {
         margin-top: 0;
     }
     .col-lg-margin-0-bottom {
         margin-bottom: 0;
     }
     .col-lg-margin-5, .col-lg-margin-5-left {
         margin-left: 5px;
     }
     .col-lg-margin-5, .col-lg-margin-5-right {
         margin-right: 5px;
     }
     .col-lg-margin-5-top {
         margin-top: 5px;
     }
     .col-lg-margin-5-bottom {
         margin-bottom: 5px;
     }
     .col-lg-margin-10, .col-lg-margin-10-left {
         margin-left: 10px;
     }
     .col-lg-margin-10, .col-lg-margin-10-right {
         margin-right: 10px;
     }
     .col-lg-margin-10-top {
         margin-top: 10px;
     }
     .col-lg-margin-10-bottom {
         margin-bottom: 10px;
     }
     .col-lg-margin-15, .col-lg-margin-15-left {
         margin-left: 15px;
     }
     .col-lg-margin-15, .col-lg-margin-15-right {
         margin-right: 15px;
     }
     .col-lg-margin-15-top {
         margin-top: 15px;
     }
     .col-lg-margin-15-bottom {
         margin-bottom: 15px;
     }
     .col-lg-margin-20, .col-lg-margin-20-left {
         margin-left: 20px;
     }
     .col-lg-margin-20, .col-lg-margin-20-right {
         margin-right: 20px;
     }
     .col-lg-margin-20-top {
         margin-top: 20px;
     }
     .col-lg-margin-20-bottom {
         margin-bottom: 20px;
     }
     .col-lg-margin-25, .col-lg-margin-25-left {
         margin-left: 25px;
     }
     .col-lg-margin-25, .col-lg-margin-25-right {
         margin-right: 25px;
     }
     .col-lg-margin-25-top {
         margin-top: 25px;
     }
     .col-lg-margin-25-bottom {
         margin-bottom: 25px;
     }
     .col-lg-margin-30, .col-lg-margin-30-left {
         margin-left: 30px;
     }
     .col-lg-margin-30, .col-lg-margin-30-right {
         margin-right: 30px;
     }
     .col-lg-margin-30-top {
         margin-top: 30px;
     }
     .col-lg-margin-30-bottom {
         margin-bottom: 30px;
     }
     .col-lg-margin-35, .col-lg-margin-35-left {
         margin-left: 35px;
     }
     .col-lg-margin-35, .col-lg-margin-35-right {
         margin-right: 35px;
     }
     .col-lg-margin-35-top {
         margin-top: 35px;
     }
     .col-lg-margin-35-bottom {
         margin-bottom: 35px;
     }
     .col-lg-margin-40, .col-lg-margin-40-left {
         margin-left: 40px;
     }
     .col-lg-margin-40, .col-lg-margin-40-right {
         margin-right: 40px;
     }
     .col-lg-margin-40-top {
         margin-top: 40px;
     }
     .col-lg-margin-40-bottom {
         margin-bottom: 40px;
     }
     .col-lg-margin-45, .col-lg-margin-45-left {
         margin-left: 45px;
     }
     .col-lg-margin-45, .col-lg-margin-45-right {
         margin-right: 45px;
     }
     .col-lg-margin-45-top {
         margin-top: 45px;
     }
     .col-lg-margin-45-bottom {
         margin-bottom: 45px;
     }
     .col-lg-margin-50, .col-lg-margin-50-left {
         margin-left: 50px;
     }
     .col-lg-margin-50, .col-lg-margin-50-right {
         margin-right: 50px;
     }
     .col-lg-margin-50-top {
         margin-top: 50px;
     }
     .col-lg-margin-50-bottom {
         margin-bottom: 50px;
     }
 }
 
 
 /*
  * Spacing and indents
  */
 .indent-5 {
     padding: 0 5px;
 }
 .indent-5-all-sides {
     padding: 5px;
 }
 .indent, .indent-10 {
     padding: 0 10px;
 }
 .indent-all-sides, .indent-10-all-sides {
     padding: 10px;
 }
 .remove-indent, .remove-indent-10 {
     margin-left: -10px;
     margin-right: -10px;
 }
 .indent-10-all-sides > .remove-indent-10:first-child {
     margin-top: -10px;
 }
 .indent-15 {
     padding: 0 15px;
 }
 .indent-15-all-sides {
     padding: 15px;
 }
 .remove-indent-15 {
     margin-left: -15px;
     margin-right: -15px;
 }
 .indent-15-all-sides > .remove-indent-15:first-child {
     margin-top: -15px;
 }
 .indent-20 {
     padding: 0 20px;
 }
 .indent-20-all-sides {
     padding: 20px;
 }
 .remove-indent-20 {
     margin-left: -20px;
     margin-right: -20px;
 }
 .indent-20-all-sides > .remove-indent-20:first-child {
     margin-top: -20px;
 }
 .top-spacing-0 {
     margin-top: 0px;
 }
 .top-spacing-5 {
     margin-top: 5px;
 }
 .top-spacing-10 {
     margin-top: 10px;
 }
 .top-spacing-15 {
     margin-top: 15px;
 }
 .top-spacing-20 {
     margin-top: 20px;
 }
 .top-spacing-25 {
     margin-top: 25px;
 }
 .top-spacing-30 {
     margin-top: 30px;
 }
 .top-spacing-35 {
     margin-top: 35px;
 }
 .top-spacing-40 {
     margin-top: 40px;
 }
 .top-spacing-45 {
     margin-top: 45px;
 }
 .top-spacing-50 {
     margin-top: 50px;
 }
 .bottom-spacing-0 {
     margin-bottom: 0px;
 }
 .bottom-spacing-5 {
     margin-bottom: 5px;
 }
 .bottom-spacing-10 {
     margin-bottom: 10px;
 }
 .bottom-spacing-15 {
     margin-bottom: 15px;
 }
 .bottom-spacing-20 {
     margin-bottom: 20px;
 }
 .bottom-spacing-25 {
     margin-bottom: 25px;
 }
 .bottom-spacing-30 {
     margin-bottom: 30px;
 }
 .bottom-spacing-35 {
     margin-bottom: 35px;
 }
 .bottom-spacing-40 {
     margin-bottom: 40px;
 }
 .bottom-spacing-45 {
     margin-bottom: 45px;
 }
 .bottom-spacing-50 {
     margin-bottom: 50px;
 }
 .left-spacing-0 {
     margin-left: 0px;
 }
 .left-spacing-5 {
     margin-left: 5px;
 }
 .left-spacing-10 {
     margin-left: 10px;
 }
 .left-spacing-15 {
     margin-left: 15px;
 }
 .left-spacing-20 {
     margin-left: 20px;
 }
 .left-spacing-25 {
     margin-left: 25px;
 }
 .left-spacing-30 {
     margin-left: 30px;
 }
 .left-spacing-35 {
     margin-left: 35px;
 }
 .left-spacing-40 {
     margin-left: 40px;
 }
 .left-spacing-45 {
     margin-left: 45px;
 }
 .left-spacing-50 {
     margin-left: 50px;
 }
 .right-spacing-0 {
     margin-right: 0px;
 }
 .right-spacing-5 {
     margin-right: 5px;
 }
 .right-spacing-10 {
     margin-right: 10px;
 }
 .right-spacing-15 {
     margin-right: 15px;
 }
 .right-spacing-20 {
     margin-right: 20px;
 }
 .right-spacing-25 {
     margin-right: 25px;
 }
 .right-spacing-30 {
     margin-right: 30px;
 }
 .right-spacing-35 {
     margin-right: 35px;
 }
 .right-spacing-40 {
     margin-right: 40px;
 }
 .right-spacing-45 {
     margin-right: 45px;
 }
 .right-spacing-50 {
     margin-right: 50px;
 }
 .no-spacing {
     margin: 0;
     padding: 0;
 }
 .no-top-spacing {
     margin-top: 0;
     padding-top: 0;
 }
 .no-right-spacing {
     margin-right: 0;
     padding-right: 0;
 }
 .no-bottom-spacing {
     margin-bottom: 0;
     padding-bottom: 0;
 }
 .no-left-spacing {
     margin-left: 0;
     padding-left: 0;
 }
 .no-padding {
     padding: 0;
 }
 .no-top-padding {
     padding-top: 0;
 }
 .no-right-padding {
     padding-right: 0;
 }
 .no-bottom-padding {
     padding-bottom: 0;
 }
 .no-left-padding {
     padding-left: 0;
 }
 
 /**
         SPACING, MARGINS, PADDING, END.
  */
 
 /*
  * Floats
  */
 .floatleft, .float-left{
     float: left;
 }
 .floatright, .float-right{
     float: right;
 }
 
 /**
                     Text and Fonts
  */
 .caps, .web-font .caps {
     text-transform:uppercase;
 }
 .no-caps, .web-font .no-caps {
     text-transform:none;
 }
 .lowercase {
     text-transform: lowercase;
 }
 
 /*
  * Text alignment, colors, and styles
  */
 /* Text sizing - base font is set at 16px. The following classes are intended to increase or decrease text by 1px in relation to 14px.
    The most common font sizes are: 13, 15, 18, 22/23, 28, 36 */
 .font-size-m3, .web-font .font-size-m3 {
     font-size: var(--font-size-m3);  /* 13px */
 }
 .font-size-m2, .web-font .font-size-m2 {
     font-size: var(--font-size-m2);  /* 14px */
 }
 .font-size-m1, .web-font .font-size-m1 {
     font-size: var(--font-size-m1);  /* 15px */
 }
 .font-size-0, .font-size-m0, .font-size-p0, .web-font .font-size-0, .web-font .font-size-m0, .web-font .font-size-p0 {
     font-size: var(--font-size-0);  /* 16px */
 }
 .font-size-p1, .web-font .font-size-p1 {
     font-size: var(--font-size-p1);  /* 17px */
 }
 .font-size-p2, .web-font .font-size-p2 {
     font-size: var(--font-size-p2);  /* 18px */
 }
 .font-size-p3, .web-font .font-size-p3 {
     font-size: var(--font-size-p3);  /* 19px */
 }
 .font-size-p4, .web-font .font-size-p4 {
     font-size: var(--font-size-p4);  /* 20px */
 }
 .font-size-p5, .web-font .font-size-p5 {
     font-size: var(--font-size-p5);  /* 21px */
 }
 .font-size-p6, .web-font .font-size-p6 {
     font-size: var(--font-size-p6);  /* 22px */
 }
 .font-size-p7, .web-font .font-size-p7 {
     font-size: var(--font-size-p7);  /* 23px */
 }
 .font-size-p8, .web-font .font-size-p8 {
     font-size: var(--font-size-p8);  /* 24px */
 }
 .font-size-p9, .web-font .font-size-p9 {
     font-size: var(--font-size-p9);  /* 25px */
 }
 .font-size-p10, .web-font .font-size-p10 {
     font-size: var(--font-size-p10);  /* 26px */
 }
 .font-size-p11, .web-font .font-size-p11 {
     font-size: var(--font-size-p11);  /* 27px */
 }
 .font-size-p12, .web-font .font-size-p12 {
     font-size: var(--font-size-p12);  /* 28px */
 }
 .font-size-p13, .web-font .font-size-p13 {
     font-size: var(--font-size-p13);  /* 29px */
 }
 .font-size-p14, .web-font .font-size-p14 {
     font-size: var(--font-size-p14);  /* 30px */
 }
 .font-size-p15, .web-font .font-size-p15 {
     font-size: var(--font-size-p15);  /* 31px */
 }
 .font-size-p16, .web-font .font-size-p16 {
     font-size: var(--font-size-p16);  /* 32px */
 }
 .font-size-p17, .web-font .font-size-p17 {
     font-size: var(--font-size-p17);  /* 33px */
 }
 .font-size-p18, .web-font .font-size-p18 {
     font-size: var(--font-size-p18);  /* 34px */
 }
 .font-size-p19, .web-font .font-size-p19 {
     font-size: var(--font-size-p19);  /* 35px */
 }
 .font-size-p20, .web-font .font-size-p20 {
     font-size: var(--font-size-p20);  /* 36px */
 }
 .font-size-p21, .web-font .font-size-p21 {
     font-size: var(--font-size-p21);  /* 37px */
 }
 .font-size-p22, .web-font .font-size-p22 {
     font-size: var(--font-size-p22);  /* 38px */
 }
 .font-size-p23, .web-font .font-size-p23 {
     font-size: var(--font-size-p23);  /* 39px */
 }
 
 /*text styles*/
 .font-weight-700 {
     font-weight: 700;
 }
 .web-font .font-weight-700 {
     font-family:var(--domaine-display-bold);
     font-weight: 700;
 }
 .font-weight-400, .no-bold {
     font-weight: 400;
 }
 .font-style-oblique {
     font-style: oblique;
 }
 .font-style-normal {
     font-style: normal;
 }
 .underline {
     text-decoration: underline;
 }
 .disclaimer {
     clear: both;
     font-size: 1em; /* 14px */
 }
 
 /*text alignment of columns*/
 .text-left, .align-left, .text-xs-left {
     text-align: left;
 }
 .text-right, .align-right, .text-xs-right {
     text-align: right;
 }
 .text-center, .align-center, .text-xs-center {
     text-align: center;
 }
 .text-justify, .text-xs-justify {
     text-align: justify;
 }
 .text-nowrap, .no-wrap, .text-xs-nowrap {
     white-space: nowrap;
 }
 .text-breakword, .break-word, .text-xs-breakword {
     word-wrap: break-word;
 }
 .text-truncate, .text-xs-truncate {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }
 @media (min-width: 40em) {
     .text-sm-left {
         text-align: left;
     }
     .text-sm-right {
         text-align: right;
     }
     .text-sm-center {
         text-align: center;
     }
     .text-sm-justify {
         text-align: justify;
     }
     .text-sm-nowrap {
         white-space: nowrap;
     }
     .text-sm-breakword {
         word-wrap: break-word;
     }
     .text-sm-truncate {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
     }
 }
 @media (min-width: 48em) {
     .text-md-left {
         text-align: left;
     }
     .text-md-right {
         text-align: right;
     }
     .text-md-center {
         text-align: center;
     }
     .text-md-justify {
         text-align: justify;
     }
     .text-md-nowrap {
         white-space: nowrap;
     }
     .text-md-breakword {
         word-wrap: break-word;
     }
     .text-md-truncate {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
     }
 }
 @media (min-width: 62em) {
     .text-lg-left {
         text-align: left;
     }
     .text-lg-right {
         text-align: right;
     }
     .text-lg-center {
         text-align: center;
     }
     .text-lg-justify {
         text-align: justify;
     }
     .text-lg-nowrap {
         white-space: nowrap;
     }
     .text-lg-breakword {
         word-wrap: break-word;
     }
     .text-lg-truncate {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
     }
 }
 
 .align-top {
     vertical-align: top;
 }
 .align-middle {
     vertical-align: middle;
 }
 .align-bottom {
     vertical-align: bottom;
 }
 /*
  * Misc. Treatments
  */
 .rounded-corners {
     border-radius: 10px;
 }
 .top-box-shadow {
     box-shadow: 0px -4px 9px -3px #00000033;
     background: gray;
 }
 .bottom-box-shadow {
     box-shadow: 0px 5px 6px #0000004D;
 }
 
 /* text color TODO: needs variables.*/
 .gray-333 {
     color: #333;
 }
 .gray-666 {
     color: #666;
 }
 .gray, .gray-999 {
     color: #999;
 }
 .black, .price {
     color: #000;
 }
 .white {
     color: #fff;
 }
 .blue {
     color: #0054A0;
 }
 .green, .accepted, .complete, .success, .onboard-credit {
     color: #648326;
 }
 .purple {
     color: #845aa5;
 }
 .orange, .optional {
     color: #f05a28;
 }
 .red, .cancel, .delete, .alert, .error, .errorMessage, .incomplete, .invalid, .sale {
     color: #bd0000;
 }
 
 /*
  * Web Font Classes. The "gotham" classes are ubiquitous across princess.com, thus we use them to map to our new fonts.
    "proxima-nova" classes are here to provide access to the more modern naming conventions.
  */
 .web-font .gotham-xn-book, .gotham-xn-book {
     font-family: var(--proxima-nova);
 }
 .web-font .gotham-xn-medium, .gotham-xn-medium {
     font-family: var(--proxima-nova-medium);
 }
 .web-font .gotham-book, .gotham-book {
     font-family: var(--proxima-nova);
 }
 .web-font .gotham-book-it, .gotham-book-it {
     font-family: var(--proxima-nova-it);
 }
 .web-font .gotham-bold, .gotham-bold {
     font-family: var(--proxima-nova-semi-bold);
 }
 .web-font .gotham-bold-it, .gotham-bold-it {
     font-family: var(--proxima-nova-semi-bold-it);
 }
 .web-font .roboto, .roboto {
     font-family: var(--proxima-nova);
 }
 .web-font .default-sans-serif, .default-sans-serif {
     font-family: var(--default-sans-serif);
 }
 /* Proxima Nova Font Families */
 .web-font .proxima-nova, .proxima-nova {
     font-family: var(--proxima-nova);
 }
 .web-font .proxima-nova-it, .proxima-nova-it {
     font-family: var(--proxima-nova-it);
 }
 .web-font .proxima-nova-medium, .proxima-nova-medium {
     font-family: var(--proxima-nova-medium);
 }
 .web-font .proxima-nova-medium-it, .proxima-nova-medium-it {
     font-family: var(--proxima-nova-medium-it);
 }
 .web-font .proxima-nova-semi-bold, .proxima-nova-semi-bold {
     font-family: var(--proxima-nova-semi-bold);
 }
 .web-font .proxima-nova-semi-bold-it, .proxima-nova-semi-bold-it {
     font-family: var(--proxima-nova-semi-bold-it);
 }
 .web-font .proxima-nova-bold, .proxima-nova-bold {
     font-family: var(--proxima-nova-bold);
 }
 .web-font .proxima-nova-bold-it, .proxima-nova-bold-it {
     font-family: var(--proxima-nova-bold-it);
 }
 .web-font .domaine-display, .domaine-display-regular {
     font-family: var(--domaine-display-regular);
 }
 .web-font .domaine-display-medium, .domaine-display-medium {
     font-family: var(--domaine-display-medium);
 }
 .web-font .domaine-display-bold, .domaine-display-bold {
     font-family: var(--domaine-display-bold);
 }